import React from 'react';
import LogInForm from './components/LogInForm';
import imgLoginbg from '../img/home.jpg';
import svgiconproduct from '../img/logo.png';
const LogIn = () => (
<div>
  
<img src={imgLoginbg} width={'50%'} className="full-bg" alt="" />      
<div className="account">
  <div className="account__wrapper">
      <div className="account__card"> <img src={svgiconproduct} alt="" style={{"width":"90%"}}   />     
        <div className="account__head">      
          <h3 className="account__title">Bienvenido al 
            <span className="account__logo"> Sistema de Envio <br></br> 
               <span className="account__logo-accent"> de datos a la D.G.A.</span>
           
            </span>
            </h3>
           
          <h4 className="account__subhead subhead">Recepción, visualización y envio de las mediciones de sus pozos</h4>
        </div>
        <LogInForm onSubmit />
        </div>      
      </div>
    </div>
  </div>
);

export default LogIn;
