import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Graficos from './components/Graficos';

const Grafica = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        </Col>
    </Row>
    <Row>
      <Graficos />
    </Row>
  </Container>
);

export default Grafica;
