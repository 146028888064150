import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap';

import { Card, CardBody, Col, Input } from 'reactstrap';

class RegistroUsuario extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            rut: "",
            apellido: "",
            correo: "",
            pass: "",
            alertEnvioExitoso: false,
            nombre: "",
            segundaPass: "",
            dv: "",
            admin: false
        };
        this.handleChangeRut = this.handleChangeRut.bind(this);
        this.registrarUsuario = this.registrarUsuario.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.handleChangeApellido = this.handleChangeApellido.bind(this);
        this.handleChangeCorreo = this.handleChangeCorreo.bind(this);
        this.usuarioAdministrador = this.usuarioAdministrador.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.handleChangeSegundaPass = this.handleChangeSegundaPass.bind(this);
    }

    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({ nombre: event.target.value });
    }
    handleChangeRut(event) {
        event.preventDefault();
        this.setState({ rut: event.target.value });
    }
    handleChangeApellido(event) {
        event.preventDefault();
        this.setState({ apellido: event.target.value });
    }
    handleChangeCorreo(event) {
        event.preventDefault();
        this.setState({ correo: event.target.value });
    }
    handleChangePass(event) {
        event.preventDefault();
        this.setState({ pass: event.target.value });
    }
    handleChangeSegundaPass(event) {
        event.preventDefault();
        this.setState({ segundaPass: event.target.value });
    }
    usuarioAdministrador() {
        if (this.state.admin) {
            this.setState({ admin: false });
        } else {
            this.setState({ admin: true });

        }
    }

    formateaRut(rut) {
        if (rut !== undefined) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 === 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            this.setState({ rut: actualLimpio });
            this.setState({ dv: dv })
        }
    }

    registrarUsuario(event) {
        console.log("entro");
        event.preventDefault();
        let estado
        if (this.state.admin) {
            estado = 0
        } else {
            estado = 1
        }
        const obj = { correo: this.state.correo, pass: this.state.pass, apellido: this.state.apellido, dv: this.state.dv, nombre: this.state.nombre, rut: this.state.rut, estado: estado };
        console.log(obj);
        try {
            if (this.state.segundaPass === this.state.pass) {
                   axios.post('https://dga.sanjoseapps.cl/DGA/api/registrarUsuario.php', obj).then(response => {
                    const datos = response.data;
                    if (datos !== undefined) {
                        console.log(datos);
                    };});
                this.setState({ alertEnvioExitoso: true });
                setTimeout(() => { this.setState({ alertEnvioExitoso: false }) }, 5000)
            } else {
                alert("Las contraseñas deben coincidir");
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <React.Fragment>

                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">En esta pestaña se podrá crear un usuario</h5>
                            </div>
                            <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                                <Alert.Heading>¡Registro Exitoso!</Alert.Heading>
                                <p>
                                    Se ha registrado el usuario {this.state.nombre}.
                                </p>
                            </Alert>
                            <form onSubmit={this.registrarUsuario} className="login__form active" id="registerUsuario">
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <h4>     NOMBRES </h4>
                                        </div>
                                        <div>
                                            <Form.Control type="text" placeholder="Nombre" value={this.state.nombre} onChange={this.handleChangeNombre} maxLength={45} required />

                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <h4>     APELLIDOS</h4>
                                        </div>
                                        <div>
                                            <Form.Control type="text" placeholder="Apellido" maxLength={45} value={this.state.apellido} onChange={this.handleChangeApellido} required />

                                        </div>
                                    </div>
                                </div>

                                <br></br>

                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>  <h4>  EMAIL </h4></div>
                                        <div>
                                            <Form.Control type="email" placeholder="Correo" value={this.state.correo} onChange={this.handleChangeCorreo} maxLength={45} required />
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>  <h4>  CONTRASEÑA </h4> </div>
                                        <div>
                                            <Form.Control type="password" placeholder="Contraseña" value={this.state.pass} onChange={this.handleChangePass} maxLength={45} required />
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>   <h4>  VALIDACIÓN CONTRASEÑA  </h4> </div>
                                        <div>
                                            <Form.Control type="password" placeholder="Validación Contraseña" value={this.state.segundaPass} onChange={this.handleChangeSegundaPass} maxLength={45} required />
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>    </div>
                                        <div>
                                            <Input type="checkbox" onChange={e => this.usuarioAdministrador()} />  <h4> USUARIO ADMINISTRADOR  </h4>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <Form.Control type="submit" value="Registrar usuario" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}

export default RegistroUsuario;