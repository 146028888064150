import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Form} from 'react-bootstrap';

import { Card, CardBody, Col, Input } from 'reactstrap';

class EditarUsuario extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            rut: "",
            apellido: "",
            correo: "",
            pass: "",
            alertEnvioExitoso: false,
            nombre: "",
            segundaPass: "",
            lista_usuarios:[],
            dv: "",
            admin:false
        };
        this.handleChangeRut = this.handleChangeRut.bind(this);
        this.actualizarUsuario = this.actualizarUsuario.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.handleChangeApellido = this.handleChangeApellido.bind(this);
        this.handleChangeCorreo = this.handleChangeCorreo.bind(this);
        this.usuarioAdministrador = this.usuarioAdministrador.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.handleChangeSegundaPass = this.handleChangeSegundaPass.bind(this);
        this.traerUsuarios = this.traerUsuarios.bind(this);
        this.cargarDatos = this.cargarDatos.bind(this)
    }

    componentDidMount(){
        this.traerUsuarios();
    }

    traerUsuarios(){
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario')};
        console.log(obj)
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/traerUsuarios.php', obj).then(response => {
                const datos = response.data
                console.log(datos);
                if (datos !== undefined) {
                    this.setState({ lista_usuarios: response.data })
                  
                } else {
                    //console.log("No existen codigos sin asignar");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    
    cargarDatos(event){
        event.preventDefault();
        let id =   event.target.value
        let lista_usuarios = this.state.lista_usuarios
        let nombre_usuario
        let apellido_usuario
        let mail_usuario 
        let rut_usuario
        let estado_registro 
        lista_usuarios.map( (lista) => {  if(lista.idtbl_usuario === id ){ nombre_usuario = lista.nombre_usuario} } );
        lista_usuarios.map( (lista) => {  if(lista.idtbl_usuario === id ){ apellido_usuario = lista.apellido_usuario} } );
        lista_usuarios.map( (lista) => {  if(lista.idtbl_usuario === id ){ rut_usuario = lista.rut_usuario} } );
        lista_usuarios.map( (lista) => {  if(lista.idtbl_usuario === id ){ mail_usuario = lista.mail_usuario} } );
        lista_usuarios.map( (lista) => {  if(lista.idtbl_usuario === id ){ estado_registro = lista.estado_registro} } );
        this.setState({ nombre: nombre_usuario});
        this.setState({ apellido: apellido_usuario });
        this.setState({ rut:rut_usuario});
        this.setState({ correo: mail_usuario });
        if(estado_registro){
            this.setState({ admin: true});
        }else{
            this.setState({ admin: false});
            
        }
    }

    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({ nombre: event.target.value });
    }
    handleChangeRut(event) {
        event.preventDefault();
        this.setState({ rut: event.target.value });
    }
    handleChangeApellido(event) {
        event.preventDefault();
        this.setState({ apellido: event.target.value });
    }
    handleChangeCorreo(event) {
        event.preventDefault();
        this.setState({ correo: event.target.value });
    }
    handleChangePass(event) {
        event.preventDefault();
        this.setState({ pass: event.target.value });
    }
    handleChangeSegundaPass(event) {
        event.preventDefault();
        this.setState({ segundaPass: event.target.value });
    }
    usuarioAdministrador(){
        if(this.state.admin){
            this.setState({ admin: false});
        }else{
            this.setState({ admin: true});
            
        }
      }

    formateaRut(rut) {
        if (rut !== undefined) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 === 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            this.setState({ rut: actualLimpio });
            this.setState({ dv: dv })
        }
    }

    actualizarUsuario(event) {
        event.preventDefault();
        let estado 
        if(this.state.admin){
          estado = 0
        }else{
          estado = 1   
        }
        const obj = { correo: this.state.correo, pass: this.state.pass, apellido: this.state.apellido, dv: this.state.dv, nombre: this.state.nombre, rut: this.state.rut, estado:estado };
        try {
            if (this.state.segundaPass === this.state.pass) {
                axios.post('https://dga.sanjoseapps.cl/DGA/api/actualizarUsuario.php', obj);
                this.setState({ alertEnvioExitoso: true });
                setTimeout(() => { this.setState({ alertEnvioExitoso: false }) }, 5000)
            } else {
                alert("Las contraseñas deben coincidir");
            }
        } catch (error) {
            //console.log(error);
        }
    }

    render() {
        return (
            <React.Fragment>
               
                <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text">En esta pestaña se podrá editar un usuario</h5>
                                </div>
                                <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                    <Alert.Heading>¡Actualización Exitosa!</Alert.Heading>
                    <p>
                        Se ha actualizado el usuario {this.state.nombre}.
                </p>
                </Alert>

                                <form onSubmit={this.actualizarUsuario} className="login__form active" id="register_">
                                <div className="row">
                                        <div className="col-md-6" style={{"marginLeft": "0"}}>
                                            <div>
                                            <h4> USUARIOS </h4>
                                            </div>
                                            <div>
                                                <Form.Control as="select" id={"3"} onChange={this.cargarDatos} >
                                                    <option value={-1} > SELECCIONE EL USUARIO</option>
                                                    {this.state.lista_usuarios.map((r) => {
                                                        return (< option key={r.idtbl_usuario} value={r.idtbl_usuario}> {r.nombre_usuario + " " + r.apellido_usuario} </option>)
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </div>
                                      <div className="row">
                                        <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                            <div>
                                            <h4>     NOMBRES </h4>
                                            </div>
                                            <div>
                                            <Form.Control  type="text" placeholder="Nombre" value={this.state.nombre} onChange={this.handleChangeNombre} maxLength={45} required    />
                               
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                            <div>
                                            <h4>     APELLIDOS</h4>
                                           </div>
                                            <div>
                                            <Form.Control type="text" placeholder="Apellido" maxLength={45} value={this.state.apellido} onChange={this.handleChangeApellido} required    />
                               
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>     
                                     <div className="row">
                                        <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                            <div>  <h4>  EMAIL </h4></div>
                                            <div>
                                            <Form.Control type="email" placeholder="Correo" value={this.state.correo} onChange={this.handleChangeCorreo} maxLength={45} required   />
                                          </div>
                                        </div>
                                    </div>
                                    <br></br>
                                           
                                    <div className="row">
                                        <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                            <div>    </div>
                                            <div>
                                            <Input type="checkbox" checked={this.state.admin}  onChange={e => this.usuarioAdministrador()} />  <h4> USUARIO ADMINISTRADOR  </h4>
                                                 </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                            <div>
                                            <Form.Control type="submit" value="Actualizar usuario"     />
                                              </div>
                                        </div>
                                    </div>
                                </form>
                    </CardBody>
                 </Card>
            </Col>
            </React.Fragment>
        )
    }
}

export default EditarUsuario;