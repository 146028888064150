import React, { PureComponent } from 'react';
import { Field, reduxForm, formValueSelector  } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { Alert } from 'reactstrap';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux'

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      mail: '',
      password: '',
      showButton: false,
      usuario: [],
      alertError: false,
      BtnLoginColorVerde: "active",
      BtnRegistrarColorVerde: "",
      navigate: false
    };
    this.obtenerUsuario = this.obtenerUsuario.bind(this);
    this.handleChangeMail = this.handleChangeMail.bind(this);
    this.handleChangePass = this.handleChangePass.bind(this);
  
  }

  componentDidMount(){

    localStorage.setItem('idtbl_usuario',  undefined)
    localStorage.setItem('nombre_usuario',  undefined)
    localStorage.setItem('apellido_usuario',  undefined)
    localStorage.setItem('rut_usuario',  undefined)
    localStorage.setItem('dv_usuario',  undefined)
    localStorage.setItem('mail_usuario',  undefined)
    localStorage.setItem('estado_registro',  undefined)
    localStorage.setItem('fechaServidor',  undefined)
    localStorage.setItem('fechaAyer',  undefined )

  }
  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleChangeMail(event) {
    event.preventDefault();
    this.setState({ mail: event.target.value });
  }

  handleChangePass(event) {
    event.preventDefault();
    console.log(event.target.value)
    this.setState({ password: event.target.value });
  }


  async obtenerUsuario(event) {
    event.preventDefault();
    const obj = { mail: this.props.mail, password: this.props.password };
    try {
      const res = await axios.post('https://dga.sanjoseapps.cl/DGA/api/obtenerUsuario.php', obj);
      this.setState({
        usuario: res.data[0]
      });
      if (this.state.usuario !== undefined) {
        localStorage.setItem('idtbl_usuario', this.state.usuario.idtbl_usuario)
        localStorage.setItem('nombre_usuario', this.state.usuario.nombre_usuario)
        localStorage.setItem('apellido_usuario', this.state.usuario.apellido_usuario)
        localStorage.setItem('rut_usuario', this.state.usuario.rut_usuario)
        localStorage.setItem('dv_usuario', this.state.usuario.dv_usuario)
        localStorage.setItem('mail_usuario', this.state.usuario.mail_usuario)
        localStorage.setItem('estado_registro', this.state.usuario.estado_registro)
        localStorage.setItem('fechaServidor', this.state.usuario.fechaServidor)
        localStorage.setItem('fechaAyer', this.state.usuario.fechaAyer)
        this.setState({ navigate: true })
      }
      else {
        this.setState({ alertError: true });
        setTimeout(() => { this.setState({ alertError: false }) }, 5000)
        
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state; 
    if (this.state.navigate) {
      return <Redirect to="/bandeja" push={true} />
  }
    return (
    <div>
     <Alert color="danger" isOpen={this.state.alertError} >
      <p><span className="bold-text">Error!</span> El mail o contraseña no son correctas.
      </p>
    </Alert>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Correo</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="mail"
              component="input"
              type="text"
              placeholder="Correo electrónico"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Contraseña</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Contraseña"
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Recordar contraseña"
            />
          </div>
        </div>
         <Link className="btn btn-primary account__btn account__btn--small" to="/pages/one" onClick={this.obtenerUsuario}>Ingresar</Link>
      </form>
      </div>
    );
  }
}

const selector = formValueSelector('log_in_form')

export default compose(
connect(state => {
  const { password, mail } = selector(state, 'password', 'mail')
  return {
    password,
    mail
  }
}), reduxForm({
  
  form: 'log_in_form',
}))(LogInForm);
