import React from 'react';
import { Card, Col,CardBody, Container, Row } from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap';
import RegistroUsuario from './components/RegistroUsuario';
import EditarUsuario from './components/EditarUsuario';
import AsignacionUsuario from './components/AsignacionUsuario';
const RegistrarUsuario = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>

      <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
      <Tabs defaultActiveKey="NoEnviados" id="uncontrolled-tab-example" >
        <Tab eventKey="NoEnviados" title="Registrar Usuario Nuevo">
        <RegistroUsuario />
      </Tab>
        <Tab eventKey="Editar" title="Editar Usuario">
        <EditarUsuario />
        </Tab>
        <Tab eventKey="Asignar" title="Asignar Campo a Usuario">
        <AsignacionUsuario />
        </Tab>
      </Tabs>


      </CardBody>
                 </Card>
            </Col>
    </Row>
  </Container>
);

export default RegistrarUsuario;
