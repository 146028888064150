import React from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Card, CardBody, Col, Input } from 'reactstrap';

class EditarPozo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            idtbl_campo: 0,
            filtrousuario: [],
            nombre: '',
            caudal: '',
            esfiltrado: false,
            mostrarCodigoPozo: true,
            filtrocampo: [],
            dias_plazo: 1000,
            activo: false,
            valorMaximoCaudal: 1000,
            activo: false,
            volumen_inscrito: '',
            alertEnvioExitoso: false,
            pozos: [], 
            rut_informante:'',
            password:'',
            largoAnteriorCodigoPozo: 0,
            campo: '',
            codigo: '',
            listaStandard: [],
            standard: '', regexp: /^[0-9,\b]+$/,
            infoCaudales: 'Seleccione el Standard del Pozo'
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.handleChangeStandard = this.handleChangeStandard.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.handleChangeStandard = this.handleChangeStandard.bind(this);
        this.handleChangeCodigo = this.handleChangeCodigo.bind(this);
        this.actualizarPozo = this.actualizarPozo.bind(this);
        this.activarPozo = this.activarPozo.bind(this);
        this.handleChangeCaudal = this.handleChangeCaudal.bind(this);
        this.filtroCampo = this.filtroCampo.bind(this);
        this.mostrarCodigoPozo = this.mostrarCodigoPozo.bind(this);
        this.handleChangeCampo = this.handleChangeCampo.bind(this);
        this.envioAutomatico = this.envioAutomatico.bind(this);
        this.handleChangeRut = this.handleChangeRut.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.codigosPozos = this.codigosPozos.bind(this);
        this.handleChangeCodigoDropBox = this.handleChangeCodigoDropBox.bind(this);
        this.listaStandard = this.listaStandard.bind(this);
        this.handleChangeVolumenAnual = this.handleChangeVolumenAnual.bind(this);
    }
    componentDidMount() {
        this.validaLogin();
    }
    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            alert("Sesion no ha sido iniciada");
            //   window.location.href = "/"
        } else {
            this.codigosPozos();
            this.filtroCampo();
            this.listaStandard();
        }
    }
    mostrarCodigoPozo(event) {

        event.preventDefault();
        var estado = this.state.mostrarCodigoPozo;
        //console.log(this.state.mostrarCodigoPozo);
        if (estado === true) {
            this.setState({ mostrarCodigoPozo: false });
        } else {
            this.setState({ mostrarCodigoPozo: true });
        }
    }
    
    handleChangeRut(event){
        event.preventDefault();
        this.setState({ rut_informante: event.target.value });
    }

    handleChangePass(event){
        event.preventDefault();
        this.setState({ password: event.target.value });
    }

    activarPozo() {
        var estado = this.state.activo;
        if (estado === true) {
            this.setState({ activo: false });
        } else {
            this.setState({ activo: true });
        }
    }


    listaStandard() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/selectorStandard.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ listaStandard: response.data })
                } else {
                    //console.log("Filtro campo vacio");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    handleChangeVolumenAnual(event) {
        event.preventDefault();
        let volumen = event.target.value;
        if (volumen === '' || this.state.regexp.test(volumen)) {
            this.setState({ volumen_inscrito: event.target.value });
        }
    }
    filtroCampo() {
        const obj = { idtbl_usuario: '0' };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/filtrocampo.php', obj).then(response => {
                //   axios.post('httpss://localhost/SanJoseApiDGA/filtrocampo.php', obj).then(response => {
                const datos = response.data
                //console.log(datos);
                if (datos !== undefined) {
                    this.setState({ filtrocampo: response.data })
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    codigosPozos() {
        const obj = { idtbl_usuario: '0' };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/codigosPozoAsignados.php', obj).then(response => {
                const datos = response.data
                console.log(datos);
                if (datos !== undefined) {
                    this.setState({ pozos: response.data })
                } else {
                    //console.log("No existen codigos sin asignar");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    handleChangeStandard(event) {
        event.preventDefault();
        this.setState({ standard: event.target.value });
        if (event.target.value === 0) {
            this.setState({ infoCaudales: 'Iguales o menor a 0.5 Litros/seg ' })
            this.setState({ valorMaximoCaudal: 100 })
            this.setState({ valorMinimoCaudal: 0.1 })
        } else if (event.target.value === 1) {
            this.setState({ valorMaximoCaudal: 100 })
            this.setState({ valorMinimoCaudal: 0.5 })
            this.setState({ infoCaudales: 'Mayor a 0.5 Litros/seg y menor o igual a 1 Litros/seg' })
        } else if (event.target.value === 2) {
            this.setState({ infoCaudales: 'Mayor a 1 Litros/seg y menor a 11 Litros/seg' })
            this.setState({ valorMinimoCaudal: 100 })
            this.setState({ valorMaximoCaudal: 10.99 })
        } else if (event.target.value === 3) {
            this.setState({ infoCaudales: 'Igual o mayor a 11 Litros/seg ' })
            this.setState({ valorMaximoCaudal: 100 })
            this.setState({ valorMinimoCaudal: 11 })
        }
    }
    handleChangeCaudal(event) {
        event.preventDefault();
        let caudal = event.target.value;
        if (caudal === '' || this.state.regexp.test(caudal)) {
            this.setState({ caudal: event.target.value });
        }
    }
    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({ nombre: event.target.value });
    }
    handleChangeCodigo(event) {
        event.preventDefault();
        const re = /^[0-9\b]+$/;
        if (event.target.value.substr(-1) === '' || re.test(event.target.value.substr(-1)) || event.target.value.substr(-1) === '-') {
            this.setState({ value: event.target.value })
            var valor = event.target.value;
            if (valor.length < 2) {
                valor = "OB-" + valor;
            }
            if (valor.length === 2) {
                valor = "OB-"
            }
            //console.log(valor.substr(-1));
            if (valor.length === 7 && this.state.largoAnteriorCodigoPozo < valor.length) {
                valor = valor + "-";
            }
            if (valor.length === 7 && this.state.largoAnteriorCodigoPozo === valor.length && event.target.value.substr(-1) !== '-') {
                valor = valor + "-";
            }
            this.setState({ largoAnteriorCodigoPozo: valor.length })
            this.setState({ codigo: valor });
        }
    }

    handleChangeCampo(event) {
        event.preventDefault();
        var valor = event.target.value;
        this.setState({ campo: valor });

    }

    handleChangeCodigoDropBox(event) {
        event.preventDefault();
        var valor = event.target.value
        this.state.pozos.filter(x => x.codigo_pozo.includes(valor)).map((val, index) => (
            this.setState({ nombre: val.nombre_pozo, campo: val.campo_id, standard: val.id_standard, dias_plazo: val.dias_plazo, activo: val.activo, caudal: val.lts_segundo, volumen_inscrito: val.volumen_inscrito })
        ))
        this.setState({ codigo: valor });

    }
    envioAutomatico(event) {
        event.preventDefault();
        let dias_plazo = event.target.value;
        if (dias_plazo === '' || this.state.regexp.test(dias_plazo)) {
            this.setState({ dias_plazo: event.target.value });
        }
    }


    actualizarPozo(event) {
        event.preventDefault();
        const obj = { pass: this.state.password, rut_informante: this.state.rut_informante ,activo: this.state.activo, dias_plazo: this.state.dias_plazo, nombre: this.state.nombre, campo: this.state.campo, caudal: this.state.caudal, codigo: this.state.codigo, standard: this.state.standard, volumen_inscrito: this.state.volumen_inscrito };
        console.log(obj);
        if (this.state.codigo === '') {
            alert("Debe ingresar el codigo de obra")
        } else if (this.state.caudal >= this.state.valorMaximoCaudal) {
            alert("El valor del caudal excede lo permitido por el standard actual");

        } else if (this.state.caudal <= this.state.valorMinimoCaudal) {
            alert("El valor del caudal es menor a lo permitido por el standard actual");
        } else {
            try {

                if (this.state.nombre !== undefined) {
                    axios.post('https://dga.sanjoseapps.cl/DGA/api/actualizarPozo.php', obj);
                    this.setState({ alertEnvioExitoso: true })
                    setTimeout(() => { this.setState({ alertEnvioExitoso: false }) }, 5000)
                } else {
                    alert("Ha ocurrido un error!.");
                }
            } catch (error) {
                //console.log(error);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                    <Alert.Heading>¡Datos actualizados!</Alert.Heading>
                    <p>
                        Se ha actualizado la información el pozo {this.state.nombre} código {this.state.codigo}.
                    </p>
                </Alert>

                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">En esta pestaña podra realizar la edición de los Pozo en el sistema. </h5>
                            </div>

                            <form onSubmit={this.actualizarPozo} className="login__form active" id="register__form">
                                <div className="row">
                                    <div className="col-md-6" style={{ "margin-left": "0" }}>
                                        <div>
                                            <h4> CÓDIGO DEL POZO A EDITAR </h4>
                                        </div>
                                        <div>
                                            <Form.Control as="select" id={"2"} value={this.state.codigo} onChange={this.handleChangeCodigoDropBox} >
                                                <option value={-1} > SELECCIONE EL CÓDIGO DEL POZO</option>
                                                {this.state.pozos.map((r) => {
                                                    return (< option key={r.id} value={r.codigo_pozo}> {r.codigo_pozo + " (" + r.nombre_pozo + ")"} </option>)
                                                })}
                                            </Form.Control>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "margin-left": "0" }}>
                                        <div>
                                            <h4>   NOMBRE DEL POZO</h4>
                                        </div>
                                        <div>

                                            <Form.Control type="text" placeholder="Nombre Pozo" maxLength="50" value={this.state.nombre} onChange={this.handleChangeNombre} required />
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "margin-left": "0" }}>
                                        <div> <h4> ASIGNAR CAMPO AL POZO</h4> </div>
                                        <div>
                                            <Form.Control as="select" id={"1"} value={this.state.campo} onChange={this.handleChangeCampo} required >
                                                <option value={-1} > NO ASOCIAR POZO </option>
                                                {this.state.filtrocampo.map((r) => {
                                                    return (< option key={r.id} value={r.id}> {r.nombre_campo} </option>)
                                                })}
                                            </Form.Control>
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-md-6" style={{ "margin-left": "0" }}>
                                        <div> <h4>ESTANDÁR POZO </h4> </div>
                                        <div>

                                            <Form.Control as="select" id={"3"} value={this.state.standard} onChange={this.handleChangeStandard} required>
                                                <option value={-1} > SELECCIONE EL STANDARD DEL POZO</option>
                                                {this.state.listaStandard.map((r) => {
                                                    return (< option key={r.id_standard} value={r.id_standard}> {r.descripcion + ' (' + r.rango_caudal_descripcion + ')'} </option>)
                                                })}
                                            </Form.Control>
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-md-6" style={{ "margin-left": "0" }}>
                                        <div><h4>CAUDAL ASIGNADO </h4></div>
                                        <div>
                                            <Form.Control type="text" placeholder={this.state.infoCaudales} value={this.state.caudal} onChange={this.handleChangeCaudal} maxLength={5} required />
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-md-6" style={{ "margin-left": "0" }}>
                                        <div><h4>VOLUMEN INSCRITO ANUAL</h4> </div>
                                        <div>
                                            <Form.Control type="text" placeholder={"Ingrese el volumen inscrito anual"} value={this.state.volumen_inscrito} onChange={this.handleChangeVolumenAnual} maxLength={5} required />
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-5" style={{ "margin-left": "0" }}>
                                        <div><h4>RUT INFORMANTE</h4> </div>
                                        <div>
                                            <Form.Control type="text" placeholder={"12345678-9"} value={this.state.rut_informante} onChange={this.handleChangeRut} maxLength={5} required />

                                        </div>
                                    </div>

                                    <br></br>
                                    <div className="col-md-1" />
                                    <div className="col-md-5" style={{ "margin-left": "0" }}>
                                        <div> <h4> CONTRASEÑA </h4> </div>
                                        <div>
                                            <Form.Control type="text" placeholder={"Ingrese la contraseña"} value={this.state.password} onChange={this.handleChangePass} maxLength={6} required />

                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                
                                <div className="row">

                                    <div className="col-md-6" style={{ "margin-left": "0" }}>
                                        <div><h4>FRECUENCIA DE ENVIO AUTOMATICO</h4> </div>
                                        <div>

                                            <Form.Control as="select" id={"8"} value={this.state.dias_plazo} onChange={this.envioAutomatico} >
                                                <option value={-1} > SELECCIONE LA FRECUENCIA DE ENVIO AUTOMÁTICO</option>
                                                <option value={1} > 1 DÍA</option>
                                                <option value={7} > 7 DÍAS</option>
                                                <option value={30}   > 30 DÍAS</option>
                                            </Form.Control>
                                        </div>
                                    </div>
                                </div>
                                <br></br>



                                <div>
                                    <h4>
                                        <Input type="checkbox" checked={this.state.activo} onChange={e => this.activarPozo()} /> Activa el envio automatico de datos de pozos
                                    </h4>
                                </div>

                                <br></br>

                                <div className="row">
                                    <div className="col-md-6" style={{ "margin-left": "0" }}>
                                        <div>
                                            <Form.Control type="submit" value="Actualizar datos del Pozo" />

                                        </div>
                                    </div>
                                </div>
                            </form>

                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment >
        )
    }
}

export default EditarPozo;