import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Bandeja from '../Bandeja/index';
import Aprobar from '../Aprobacion/index';
import Busqueda from '../Busqueda/index';
import EnvioMail from '../EnvioMail/index';
import RegistroPozo from '../RegistroPozo/index';
import RegistroCampo from '../RegistroCampo/index';
import RegistroUsuario from '../RegistroUsuario/index';
import CambioContraseña from '../CambioContraseña/index';
import CantidadRegistros from '../AnalisisRecepcion/index'
import Grafica from '../Graficos/index';
import Notificacion from '../Notificaciones/index';


const Pages = () => (
  <Switch>
   <Route exact path="/bandeja" component={Bandeja} />
    <Route path="/aprobar" component={Aprobar} />
    <Route path="/busqueda" component={Busqueda} />
    <Route path="/soporte" component={EnvioMail} />
    <Route path="/registroCampo" component={RegistroCampo} />
    <Route path="/registroPozo" component={RegistroPozo} />
    <Route path="/registroUsuario" component={RegistroUsuario} />
    <Route path="/cambioContraseña" component={CambioContraseña} />
    <Route path="/grafica" component={Grafica} />
    <Route path="/notificacion" component={Notificacion} />
    <Route path="/cantidadRegistros" component={CantidadRegistros} />
  </Switch>
  
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={Pages} />
    </div>
  </div>
);  

const Router = () => (
  <MainWrapper basename={'/DGA/Web'}  >
    <main>
    <HashRouter >
      <Switch>
        <Route exact path="/DGA/Web/" component={LogIn} />
        <Route exact path="/" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
  </HashRouter>
    </main>
  </MainWrapper>
);

export default Router;
