import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarNotification from './TopbarNotification';
import TopbarProfile from './TopbarProfile';
import svgiconproduct from '../../img/logo.png';
import TopbarNoRegistros from './TopbarNoRegistros';
import TopbarMail from './TopbarMail';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div className="topbar">        
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link to="/"  >
          <img src={svgiconproduct} alt="" style={{"width":"110px"}}   />      
         </Link>         
         </div>
        <div className="topbar__right">
              <TopbarMail></TopbarMail>
              <TopbarNotification />
              <TopbarNoRegistros />
              <TopbarProfile />
        </div>
      </div>
    );
  }
}

export default Topbar;
