import React from 'react';
import icon_report from '../../img/reportar_dga.png';
import icon_informacion from '../../img/informacion.png'
import { Button, Badge } from 'react-bootstrap';
import { Alert } from 'reactstrap';
import { reduxForm, formValueSelector } from 'redux-form';
import axios from 'axios';
import loading from '../../img/loading.gif';
import { Redirect } from "react-router-dom";
import { compose } from 'redux';
import { connect } from 'react-redux'
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from '../../img/logo.jpg';
import { Card, CardBody, Col, Table, UncontrolledPopover,PopoverHeader,PopoverBody} from 'reactstrap';

class Notificaciones extends React.PureComponent {

    constructor() {
        super();
        this.state = {
          collapse: false,
          tablaFaltantes:[]
        };
        this.obtenerTablaFaltantes = this.obtenerTablaFaltantes.bind(this);
        this.handleCambio = this.handleCambio.bind(this);
      }
    
      componentDidMount(){
        this.obtenerTablaFaltantes();
      }
    
      obtenerTablaFaltantes() {
      const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            this.setState({ isLoading: true })
            axios.post('https://dga.sanjoseapps.cl/DGA/api/tablaFaltantes.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                   this.setState({tablaFaltantes:datos})
                   this.setState({ isLoading: false })
           
                }
            });
    
        } catch (error) {
            console.error(error);
        }
      }
    
      handleCambio(){
        if(this.state.collapse=== false){
          this.setState({collapse:true})
        }else{
          this.setState({collapse:false})
        }
      }

    cellButton(row) {
        let theButton;
        theButton = <Button variant="success" onClick={() => this.aprobacionPozo(row)}>
            <img src={icon_report} alt="" style={{ width: '5%' }} ></img> Aprobar
                      </Button>
        return theButton;
    }


    cellEnviados(row) {
        let theButton;
        if (row === 'Envio pendiente') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#ea913b"   }} pill variant="warning"> Pendiente de Aprobación </Badge>
        } else if (row === 'enviado') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#95b964" }} pill variant="success"> Enviado  </Badge>
        } else if (row === 'En proceso') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em","background-color": "#95b964" }} pill variant="primary"> Proceso de envio </Badge>
        } else if (row === 'No hay registro') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color":"#ce4552" }} pill variant="danger"> No hay registros </Badge>
        }
        return theButton;


    }

    alerta(row) {
        let theButton;
        if (row === '3') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color":"#ce4552" }} pill variant="danger"> Ningun registro recepcionado </Badge>
        } else if (row === '1') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#ea913b"   }} pill variant="warning"> Recepcionado hace más de dos días </Badge>
        } else if (row === '2') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#ce4552"  }} pill variant="danger"> Recepcion atrasada </Badge>
        } else if (row === 'NO HAY REGISTRO') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color":"#ce4552" }} pill variant="danger"> No hay registros </Badge>
        }
        return theButton;
    }

    alertaPDF(row) {
        let theButton;
        if (row === '3') {
            theButton =  "Ningun registro recepcionado" 
        } else if (row === '1') {
            theButton = "Recepcionado recientemente" 
       } else if (row === '2') {
            theButton = "Recepcionado hace más de dos día" 
        }
        return theButton;
    }

    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const doc = new jsPDF(orientation, unit, size);
        //doc.addImage(logo, "JPG", 700, 5, 110, 70);
        var totalPagesExp = '2'
        doc.setFontSize(20);
        const empresa = "Sistema Aravena Bustamante";
        const sistema = "Sistema de envio a D.G.A. ";
        const title = "Reporte de estados de pozos ";
        const headers = [["CAMPO", "POZO", "CÓDIGO DE LA OBRA" , "ULTIMA MEDICIÓN" , "STATUS" , "ENVIO", "FRECUENCIA DE ENVIO" ]];
        const data = this.state.tablaFaltantes.map(elt=> [elt.nombre_campo, elt.nombre_pozo, elt.codigo_pozo,elt.fecha_medicion, this.alertaPDF(elt.alerta) , elt.enviado, elt.frecuencia]);
        let content = {
          startY: 100,              
          head: headers,    
          didDrawPage: function (data) {
            // Header
              doc.addImage(logo, 'JPG', 700, 5, 130, 90)

              var str = 'Página ' + doc.internal.getNumberOfPages()
              // Total page number plugin only available in jspdf v1.0+
              if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp
              }
              doc.setFontSize(10)
        
              // jsPDF 1.4+ uses getWidth, <1.4 uses .width
              var pageSize = doc.internal.pageSize
              var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
              doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
          margin: { top: 95 },
          body: data,  theme: 'grid'
        };
        doc.setFontSize(20);
        doc.text(empresa, 40, 30);
        doc.setFontSize(15);
        doc.text(sistema, 40, 50);
        doc.setFontSize(13);
        doc.text(title, 40, 65);
        doc.autoTable(content);
        doc.save("Estados de pozos.pdf")
      }
    

    render() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            return <Redirect to="/DGA/Web/" push={true} />
        }
        if (this.state.navigate === true) {
            return <Redirect to="/DGA/Web/revisionPozo" push={true} />
        } else if (this.state.navigate === false) {
            return <Redirect to="/DGA/Web/aprobar" push={true} />
        } else {
            return (
                <React.Fragment>
      
                    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text"> Resumen de últimos datos recepcionados de sus campos registrados en el sistema</h5>
                                 </div>    
                       
                                <div className="form__form-group" style={{ "container": "wrap" }} >
                                    <div className="row">
                                <Button onClick={() => this.exportPDF()}>Generar PDF  </Button>
                                  <Alert  color="warning" className="alert--bordered" icon>
                                 <p style={{"color":"black"}} ><span style={{"color":"black"}} className="bold-text">¡Atención!</span> Los datos mostrados en este informe corresponde a las mediciones de las últimas 24 horas. 
                                </p>    
                                </Alert>
                                    </div>
                               
                                </div>

                                  {
                                    this.state.isLoading === true ?
                                        <section className="table table-responsive mt-md-5">
                                            <div className="container table">
                                                <div align="center"><img src={loading}  style={{"width":"10%" }} alt="loading..." /></div>
                                            </div>
                                        </section>
                                        :
                                         <div className="table" style={{ width: "90%", "margin": "auto", "fontSize": "0.9rem" }} >
                                                    <UncontrolledPopover trigger="focus" target="PopperStatus" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Status</PopoverHeader>
                                                    <PopoverBody>Indicador del tiempo que ha pasado desde que se han ingresados mediciones del pozo correspondiente.</PopoverBody>
                                                 </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="PopperEnvio" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Envio?</PopoverHeader>
                                                    <PopoverBody> Indica el estado de la última medicón registrada con respecto a su envio hacia la D.G.A.</PopoverBody>
                                                </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="PopperFrecuencia" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es la Frecuencia de envio?</PopoverHeader>
                                                    <PopoverBody>Nos indica la frecuencia de envio de datos requerida por el standard del pozo.</PopoverBody>
                                                </UncontrolledPopover>   
                                                        <Table responsive hover className="table--bordered" >
                                                            <thead>
                                                                <tr>
                                                                    <th  style={{"vertical-align": "initial"}} key={1}> <h5>CAMPO  </h5>  </th>
                                                                    <th  style={{"vertical-align": "initial"}} key={2}> <h5>POZO </h5> </th>
                                                                    <th  style={{"vertical-align": "initial"}} key={24}> <h5>CODIGO DE LA OBRA </h5> </th>
                                                                    <th  style={{"vertical-align": "initial"}} key={3}> <h5>ÚLTIMA MEDICIÓN RECEPCIONADA </h5> </th>
                                                                    <th style={{"vertical-align": "initial"}}  key={8}> <h5>STATUS  <button style={{"border-width":"0", "background":"none"}}  id="PopperStatus" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>   </h5></th>
                                                                    <th style={{"vertical-align": "initial"}}  key={9}> <h5>ENVIO  <button style={{"border-width":"0", "background":"none"}}  id="PopperEnvio" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>   </h5></th>
                                                                    <th style={{"vertical-align": "initial"}}  key={10}> <h5>FRECUENCIA ENVIO <button style={{"border-width":"0", "background":"none"}}  id="PopperFrecuencia" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>   </h5></th>
                                                                    <th style={{"vertical-align": "initial"}}  key={10}> <h5>DATOS RECEPCIONADOS </h5></th>                 
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.tablaFaltantes.map(item => (
                                                                    <tr key={item.codigo_pozo}>
                                                                        <td  ><h5> {item.nombre_campo}</h5></td>
                                                                        <td><h5>{item.nombre_pozo}</h5></td>
                                                                        <td><h5>{item.codigo_pozo} </h5></td>
                                                                        <td><h5>{item.fecha_medicion}</h5></td>
                                                                        <td><h5>{this.alerta(item.alerta)}</h5></td>
                                                                        <td><h5>{item.enviado}</h5></td>
                                                                        <td><h5>{item.frecuencia}</h5></td>
                                                                        <td><h5>{item.registros_actuales + "/" + item.cantidad}</h5></td>
                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                }
                            </CardBody>
                        </Card>
                    </Col>
                </React.Fragment >
            )
        }
    }
}


const selector = formValueSelector('log_in_form')

export default compose(
    connect(state => {
        const { campo, mail } = selector(state, 'campo', 'mail')
        return {
            campo,
            mail
        }
    }), reduxForm({

        form: 'log_in_form',
    }))(Notificaciones);
