import React from 'react';
import { Card, Col,CardBody, Container, Row } from 'reactstrap';
import RegistroPozo from './components/RegistroPozo';
import EditPozo from './components/EditarPozo';
import { Tabs, Tab } from 'react-bootstrap';

const RegistrarPozo = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>

    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
      <Tabs defaultActiveKey="NoEnviados" id="uncontrolled-tab-example" >
        <Tab eventKey="NoEnviados" title="Agregar Pozo">
          <RegistroPozo />
        </Tab>
        <Tab eventKey="Enviados" title="Editar Pozo">
          <EditPozo />
        </Tab>
      </Tabs>


      </CardBody>
                 </Card>
            </Col>
    </Row>
  </Container>
);

export default RegistrarPozo;
