import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Form, Button } from 'react-bootstrap';
import { Card, CardBody, Col, Table } from 'reactstrap';

class AsignacionUsuario extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            lista_usuarios: [],
            lista_campo: [],
            lista_campo_sin: [],
            alertEnvioExitoso: false,
            idtbl_usuario:0,
            id_campo:0
        };
        this.traerUsuarios = this.traerUsuarios.bind(this);
        this.cargarCamposAsignados = this.cargarCamposAsignados.bind(this)
        this.eliminarCampo = this.eliminarCampo.bind(this)
        this.cargarCamposSinAsignar = this.cargarCamposSinAsignar.bind(this)
        this.asignarCampo = this.asignarCampo.bind(this);
        this.campoSeleccionado = this.campoSeleccionado.bind(this);
    }

    componentDidMount() {
        this.traerUsuarios();
    }

    traerUsuarios() {
        const obj = { idtbl_usuario: 0 };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/traerUsuarios.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ lista_usuarios: response.data })
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    cargarCamposAsignados(value) {
        const obj = { idtbl_usuario: value };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/selectorCampoAsignacion.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ lista_campo: response.data })
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    cargarCamposSinAsignar(event) {
        event.preventDefault();        
        let valor = event.target.value;
        if (valor !== null ){
        this.setState({idtbl_usuario: valor })
        const obj = { idtbl_usuario: valor};
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/selectorCampoSinAsignacion.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ lista_campo_sin: response.data })
                    this.cargarCamposAsignados(valor)
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    }

    eliminarCampo(row){
        const obj = { id: row};
        try {
            if (row !== undefined) {
                axios.post('https://dga.sanjoseapps.cl/DGA/api/eliminarAsignacionCampo.php', obj).then(response => {
                    const datos = response.data
                    if (datos !== undefined) {
                        alert("Campo desasignado del usuario")    
                        this.cargarCamposAsignados(this.state.idtbl_usuario  )
                }})
            }        
        } catch (error) {
            //console.log(error);
        }
    }

    asignarCampo(event){
        event.preventDefault();
        const obj = { id_campo: this.state.id_campo, idtbl_usuario:this.state.idtbl_usuario  };
        try {
            if (this.state.id_campo !== undefined) {
                axios.post('https://dga.sanjoseapps.cl/DGA/api/asignarCampo.php', obj).then(response => {
                    const datos = response.data
                    if (datos !== undefined) {
                        alert("Campo asignado")     
                        this.cargarCamposAsignados(this.state.idtbl_usuario )
                    }})
            }        
        } catch (error) {
            //console.log(error);
        }
    }

    campoSeleccionado(event){
        event.preventDefault()
        let valor  = event.target.value;
       this.setState({id_campo:valor});
    }




    cellButton(row) {
        let theButton;
        theButton = <Button variant="success" style={{"background": "#6d9934"}}  onClick={() => this.eliminarCampo(row)}> Eliminar Asignación Campo   </Button>
        return theButton;
    }
 
    render() {
        return (
            <React.Fragment>

                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">En esta pestaña se podrá asignar campos a los usuarios</h5>
                            </div>
                            <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                                <Alert.Heading>¡Actualización Exitosa!</Alert.Heading>
                                <p>
                                    Se ha actualizado el usuario {this.state.nombre}.
                                </p>
                            </Alert>

                            <form onSubmit={this.asignarCampo} className="login__form active" id="register">
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <h4> USUARIOS </h4>
                                        </div>
                                        <div>
                                            <Form.Control as="select" id={"4"} onChange={this.cargarCamposSinAsignar} >
                                                <option value={-1} > SELECCIONE EL USUARIO</option>
                                                {this.state.lista_usuarios.map((r) => {
                                                    return (< option key={r.idtbl_usuario} value={r.idtbl_usuario}> {r.nombre_usuario + " " + r.apellido_usuario} </option>)
                                                })}
                                            </Form.Control>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <h4> CAMPO </h4>
                                        </div>
                                        <div>
                                            <Form.Control as="select" id={"5"} onChange={this.campoSeleccionado} >
                                                <option value={-1} > SELECCIONE EL CAMPO</option>
                                                {this.state.lista_campo_sin.map((r) => {
                                                    return (< option key={r.id} value={r.id_campo}> {r.nombre_campo} </option>)
                                                })}
                                            </Form.Control>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                        <div className="col-md-6" style={{"marginLeft": "0"}}>
                                            <div>
                                            <Form.Control type="submit" value="Asignar Campo " />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                    <div className="table" style={{ width: "90%", "margin": "auto", "fontSize": "0.9rem" }} >                          
                                        <Table responsive className="table--bordered">
                                           <thead>
                                                <tr>
                                                    <th key={13}> <h5>NOMBRE CAMPO </h5> </th>                                                  
                                                    <th key={23}> <h5>ACCIONES </h5> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.lista_campo.map(item => (
                                                    <tr key={item.id}>
                                                        <td><h5>{item.nombre_campo}</h5></td>
                                                        <td><h5>{this.cellButton(item.id)}</h5></td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}

export default AsignacionUsuario;