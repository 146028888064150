import React from 'react';
import { Card, Col, CardBody, Container, Row } from 'reactstrap';
import RegistroCampo from './components/RegistroCampo';
import { Tabs, Tab } from 'react-bootstrap';
import AsignarPozo from './components/AsignarPozo';

const RegistrarCampo = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <Tabs defaultActiveKey="NoEnviados" id="uncontrolled-tab-example" >
              <Tab eventKey="NoEnviados" title="Registrar Campo">
                <RegistroCampo />
              </Tab>
              <Tab eventKey="Enviados" title="Asignacion de Pozos">
                <AsignarPozo />
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default RegistrarCampo;
