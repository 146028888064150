import React from 'react';
import { Form } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import Moment from 'moment';
import { ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col, Table, UncontrolledPopover,PopoverHeader,PopoverBody} from 'reactstrap';
import icon_informacion from '../../img/informacion.png'

class Graficos extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            tablaHome: [],
            lista_pozo: [],
            lista_caudal: [],
            lista_volumen: [],
            lista_totalizador_registro: [],
            lista_caudalMaximo: [],
            lista_pozo_totalizador:[],
            lista_totalizador_calculo:[],
            lista_totalizador_base:[],
            fechaMinima: localStorage.getItem('fechaAyer'),
            fechaMaxima: localStorage.getItem('fechaServidor'),
            textoGraficoSuperior: 'Promedio Caudal Registrado vs Máximo Caudal (Últimas 24 horas)',
            textoGraficoInferior: 'Totalizador vs Derecho Anual Máximo'
        }
        this.handleChangeFechaDesde = this.handleChangeFechaDesde.bind(this);
        this.handleChangeFechaHasta = this.handleChangeFechaHasta.bind(this);
        this.obtenerDatos = this.obtenerDatos.bind(this);
        this.traerTotalizador = this.traerTotalizador.bind(this);
    }

    componentDidMount() {
        var valorDesde = localStorage.getItem('fechaAyer')
        var valorHasta = localStorage.getItem('fechaServidor')
        this.setState({ fechaMinima: valorDesde }); 
        this.setState({ fechaMaxima: valorHasta });
        this.obtenerDatos();
        this.traerTotalizador();
    }

    traerTotalizador() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/tablaGraficos_totalizador.php', obj).then(response => {
                const datos = response.data
                if (datos !== '') {
                    const lista_pozo = datos.map((item) => ((item.nombre_pozo + " " + item.nombre_campo) ));
                    const lista_totalizador_base = datos.map((item) => item.totalizador_base);    
                    const lista_totalizador_calculo = datos.map((item) => item.totalizador_calculo);
                    const lista_totalizador_registro = datos.map((item) => item.totalizador_registro);
                    const lista_volumen = datos.map((item) => item.volumen_inscrito_anual);
                    this.setState({ lista_totalizador_base: lista_totalizador_base })
                    this.setState({ lista_totalizador_calculo: lista_totalizador_calculo })
                    this.setState({ lista_totalizador_registro: lista_totalizador_registro })
                    this.setState({ lista_pozo_totalizador: lista_pozo })    
                        this.setState({ lista_volumen: lista_volumen })
                  
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    obtenerDatos() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), fechaDesde: this.state.fechaMinima, fechaHasta: this.state.fechaMaxima };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/tablaGraficos.php', obj).then(response => {
                const datos = response.data
                if (datos !== '') {
                    const lista_pozo = datos.map((item) => item.nombre_pozo);
                    const lista_caudal = datos.map((item) => parseFloat(item.caudal));
                    const lista_caudalMaximo = datos.map((item) => parseFloat(item.lts_segundo));
                   this.setState({ lista_caudalMaximo: lista_caudalMaximo })
                    this.setState({ lista_pozo: lista_pozo })
                    this.setState({ lista_caudal: lista_caudal })
                    //definir grafico 2do timestamp anual 01012021 en adelanta
                    this.setState({ textoGraficoSuperior: 'Promedio Caudal Registrado vs Máximo Caudal (' + Moment(this.state.fechaMinima).format('DD-MM-YYYY') + ' al ' + Moment(this.state.fechaMaxima).format('DD-MM-YYYY') + ')' })
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    handleChangeFechaDesde(event) {
        event.preventDefault();
        var valorDesde = event.target.value;
        this.setState({ fechaMinima: valorDesde });
       
        this.obtenerDatos();

    }

    handleChangeFechaHasta(event) {
        event.preventDefault();
        var valorHasta = event.target.value;
        this.setState({ fechaMaxima: valorHasta });
        this.obtenerDatos();

    }

    render() {
        const data = {
            labels: this.state.lista_pozo,
            datasets: [
                {
                    label: 'Promedio Caudal Registrado (l/s)',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(95, 123, 24,0.4)',
                    borderColor: 'rgba(95, 123, 24,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.lista_caudal,
                    scales: {
                        yAxes: [{
                            stacked: true
                        }]
                    }
                },
                {
                    label: 'Caudal Máximo Asignado (l/s)',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(192,75,75,0.4)',
                    borderColor: 'rgba(192,75,75,1)',
                    pointHitRadius: 10,
                    data: this.state.lista_caudalMaximo,
                    scales: {
                        yAxes: [{
                            stacked: true
                        }]
                    }
                }
            ]
        }

        const data_grafico_dos = {
            labels: this.state.lista_pozo_totalizador,
            datasets: [
                {
                    label: 'Totalizador año actual (m³)',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(95, 123, 24,0.4)',
                    borderColor: 'rgba(95, 123, 24,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.lista_totalizador_calculo,
                    scales: {
                        yAxes: [{
                            stacked: true
                        }]
                    }
                },
                
                {
                    label: 'Totalizador registrado (m³)',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(192,75,75,0.4)',
                    borderColor: 'rgba(192,75,75,1)',
                    pointHitRadius: 10,
                    data: this.state.lista_totalizador_registro,
                    hidden: true,
                    scales: {
                        yAxes: [{
                            stacked: true
                        }]
                    }
                },
                {
                    label: 'Volumen Anual Máximo (m³)',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(192,75,75,0.4)',
                    borderColor: 'rgba(192,75,75,1)',
                    pointHitRadius: 10,
                    data: this.state.lista_volumen,
                    hidden: true,
                    scales: {
                        yAxes: [{
                            stacked: true
                        }]
                    }
                }

            ]
        }

        return (
            <React.Fragment>

                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">En esta pestaña podra revisar la información general de cada pozo a través de graficas .</h5>
                            </div>
                        
                            <div className="row">
                                <h3 style={{ "margin": "2px 10px 6px 6px" }}> Fecha Desde</h3>
                                <Form.Group controlId="dob">
                                    <Form.Control type="date" defaultValue={localStorage.getItem('fechaAyer')} max={localStorage.getItem('fechaServidor')} min='2020-12-01' name="dob" onChange={this.handleChangeFechaDesde} />
                                </Form.Group>


                                <h3 style={{ "margin": "2px 10px 6px 6px" }}>Fecha Hasta</h3>
                                <Form.Group controlId="dob">
                                    <Form.Control type="date" name="dob" defaultValue={localStorage.getItem('fechaServidor')} max={localStorage.getItem('fechaServidor')} min='2020-12-01' onChange={this.handleChangeFechaHasta} />
                                </Form.Group>

                            </div>
                            <UncontrolledPopover trigger="focus" target="Inferior" placement="bottom" >                                     
                                                    <PopoverHeader>Información importante</PopoverHeader>
                                                    <PopoverBody> El cálculo realizado para saber el valor del totalizador del año actual corresponde a la resta entre el valor del totalizador del dia 31-12-2020 y el valor del totalizador más actual .</PopoverBody>
                                                </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="Estado" placement="bottom" >                                     
                                                    <PopoverHeader>Información importante</PopoverHeader>
                                                    <PopoverBody>Los pozos que no aparecen gráficados es debido a que no se encuentran mediciones registradas en las fechas establecidas.</PopoverBody>
                                                </UncontrolledPopover>   
                            <br></br>
                            <h3 >{this.state.textoGraficoSuperior} <button style={{"border-width":"0", "background":"none"}}  id="Estado" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>  </h3>
                            <br></br>
                            <ResponsiveContainer height={300}>
                                <Bar className="barra" data={data} options={{ maintainAspectRatio: false }} />
                            </ResponsiveContainer>
                            <br></br>
                            <h3>{this.state.textoGraficoInferior} <button style={{"border-width":"0", "background":"none"}}  id="Inferior" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>  </h3>
                            <br></br>
                            <ResponsiveContainer height={300}>
                                <Bar className="barra" data={data_grafico_dos} options={{ maintainAspectRatio: false }} />
                            </ResponsiveContainer>


                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}

export default Graficos;