import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    changeToGreen: PropTypes.func.isRequired, 
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };


  render() {
    const { changeToDark, changeToLight,changeToGreen } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Recepción de datos" icon="home" route="/bandeja" onClick={this.hideSidebar}>  </SidebarLink>       
          <SidebarLink title="Envio de datos" icon="rocket"  route="/aprobar" onClick={this.hideSidebar} />
          <SidebarLink title="Estado de pozos" icon="list" route="/notificacion" onClick={this.hideSidebar} />
          <SidebarLink title="Análisis Registros Recibidos" icon="list" route="/cantidadRegistros" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Búsqueda Pozo" icon="diamond" route="/busqueda" onClick={this.hideSidebar} />          
          <SidebarLink title="Graficas" icon="chart-bars" route="/grafica" onClick={this.hideSidebar} />      
        </ul>
        { localStorage.getItem('estado_registro') === "1" ?  
    
            <SidebarCategory title="Mantenedores" icon="list">
            <SidebarLink title="Mantenedor Pozo" route="/registroPozo" onClick={this.hideSidebar} />
            <SidebarLink title="Mantenedor Campo" route="/registroCampo" onClick={this.hideSidebar} />
            <SidebarLink title="Mantenedor Usuario" route="/registroUsuario" onClick={this.hideSidebar} />
          </SidebarCategory>
    
        : "" }
        <SidebarCategory title="Diseño" icon="layers">
            <button type="button" className="sidebar__link" onClick={changeToGreen  }>
              <p className="sidebar__link-title">Tema Original</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Tema Claro</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Tema Oscuro</p>
            </button>

          </SidebarCategory>                


          <SidebarLink title="Cerrar Sesión" icon="exit" route="/" onClick={this.hideSidebar} />          
      </div>
    );
  }
}

export default SidebarContent;
