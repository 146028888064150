import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BusquedaPozo from './components/BusquedaPozo';

const Busqueda = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>
      <BusquedaPozo />
    </Row>
  </Container>
);

export default Busqueda;
