import React from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { compose } from 'redux';
import { connect } from 'react-redux'
import { Card, CardBody, Col} from 'reactstrap';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import MapMarkerRadiusIcon from 'mdi-react/MapMarkerRadiusIcon';

class AnalisiRececpcion extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            lista_registros: [],
            usuarioAutorizado:false,
            fechaAntigua : ''

        };
        this.validaLogin = this.validaLogin.bind(this);
        this.obtenerTabla = this.obtenerTabla.bind(this);
        this.display = this.display.bind(this);
    }
    componentDidMount() {
        this.validaLogin()
    }

    validaLogin() {

        if (localStorage.getItem('idtbl_usuario') === undefined) {
            this.setState({ usuarioAutorizado: false });
        } else {
            this.setState({ usuarioAutorizado: true });
            this.obtenerTabla(0);
        }
    }

    obtenerTabla(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario')};
        try {
        this.setState({ isLoading: true })
            axios.post('https://dga.sanjoseapps.cl/DGA/api/cantidadesRegistros.php', obj).then(response => {
                const datos = response.data
                console.log(datos);
                if (datos !== undefined) {
                    this.setState({ lista_registros: datos })
                    this.setState({ isLoading: false })
                }
            })
            this.obtenerTablaFaltantes();
        } catch (error) {
            console.error(error);
        }
    }

    display(value)
    {


        this.setState({fechaAntigua : value} ); 
        console.log ("fecha medcion")
        console.log(value)

    }


    render() 
    {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            return <Redirect to="/" push={true} />
        } else {
            return (
                <React.Fragment>               
                     {   
                                                this.state.lista_registros.map((r) => {
                                                    let valor = (r.cantidad_registros - r.cantidad)
                                                    let porcentaje = (r.cantidad * 100)/r.cantidad_registros
                                                    if ( valor <0){ valor =0}
                                                    if (porcentaje > 100 ) {porcentaje= 100}
                                                    let data = [];
                                                    if (valor > 0 ){
                                                        data = [{ value: r.cantidad, fill: 'rgb(184, 233, 134)'} ,  { value: valor, fill: 'rgb(227, 43, 77)' }]; 
                                                    }else{
                                                        if( porcentaje = 100){
                                                         data = [{ value: 24, fill: 'rgb(184, 233, 134)'} ,  { value: valor, fill: 'rgb(227, 43, 77)' }]; 
                                                        }else{
                                                         data = [{ value: r.cantidad_registros, fill: 'rgb(184, 233, 134)'} ,  { value: valor, fill: 'rgb(227, 43, 77)' }]; 
                                                                
                                                        }
                                                    }
                                                      return(                                            
                                                            <Col md={10} xl={3} lg={5} sm={10} xs={10}>
                                                                <Card>
                                                                    <CardBody className="dashboard__health-chart-card" style={{"text-align":" center"}}>
                                                                    <div className="card__title">
                                                                        <h5 className="bold-text card__title-center" style={{ "font-size":" 20px", "margin":"0px"}} >{ r.fechaMedicion }</h5>
                                                                        <h5 className="bold-text card__title-center" style={{ "font-size":" 15px", "margin":"0px"}}>{r.nombre_pozo +" - " + r.nombre_campo }</h5>
                                                                    </div>
                                                                    <div className="dashboard__health-chart">
                                                                        <ResponsiveContainer height={180}>
                                                                        <PieChart>
                                                                            <Pie data={data} dataKey="value" cy={85}  innerRadius={80} outerRadius={90} />
                                                                        </PieChart>
                                                                        </ResponsiveContainer>
                                                                        <div className="dashboard__health-chart-info" style={{"position": "absolute", "width": "100%", "top": "calc(50% - 55px)", "-webkit-animation": "label 1.5s ease-in", "margin" : "0px 0px -30px -30px"}}>
                                                                        <p className="dashboard__health-chart-number" style={{ "font-size":" 48px"," line-height": "60px", "margin":"0px" }  } > {porcentaje + "%" }</p>
                                                                        <p className="dashboard__health-chart-units" style={{ "font-size":" 16px", "margin":"0px"}}>  { "recibidas"} </p>
                                                                        </div>
                                                                    </div>
                                                                    <p className="bold-text  dashboard__goal">{ " Cantidad esperada : "+ r.cantidad_registros}</p>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            )
                                                    
                                                     
                                                                /*
                                                    return (
                                                        <Col md={12} xl={3} lg={6} sm={12} xs={12}>
                                                        <Card>
                                                          <CardBody className="dashboard__health-chart-card" style={{"text-align":" center"}}>
                                                            <div className="card__title">
                                                              <h5 className="bold-text card__title-center" style={{ "font-size":" 20px", "margin":"0px"}} >{ r.fechaMedicion }</h5>
                                                              <h5 className="bold-text card__title-center" style={{ "font-size":" 15px", "margin":"0px"}}>{r.nombre_pozo +" - " + r.nombre_campo }</h5>
                                                            </div>
                                                            <div className="dashboard__health-chart">
                                                              <ResponsiveContainer height={180}>
                                                                <PieChart>
                                                                  <Pie data={data} dataKey="value" cy={85}  innerRadius={80} outerRadius={90} />
                                                                </PieChart>
                                                              </ResponsiveContainer>
                                                              <div className="dashboard__health-chart-info" style={{"position": "absolute", "width": "100%", "top": "calc(50% - 55px)", "-webkit-animation": "label 1.5s ease-in", "margin" : "0px 0px -30px -30px"}}>
                                                              <p className="dashboard__health-chart-number" style={{ "font-size":" 48px"," line-height": "60px", "margin":"0px" }  } > {porcentaje + "%" }</p>
                                                                <p className="dashboard__health-chart-units" style={{ "font-size":" 16px", "margin":"0px"}}>  { "recibidas"} </p>
                                                              </div>
                                                            </div>
                                                            <p className="bold-text  dashboard__goal">{ " Cantidad esperada : "+ r.cantidad_registros}</p>
                                                          </CardBody>
                                                        </Card>
                                                      </Col>
                                                    ) */
                                               })}
                </React.Fragment >
            )
        }
    }
}


const selector = formValueSelector('log_in_form')

export default compose(
    connect(state => {
        const { campo, mail } = selector(state, 'campo', 'mail')
        return {
            campo,
            mail
        }
    }), reduxForm({

        form: 'log_in_form',
    }))(AnalisiRececpcion);
