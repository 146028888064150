import React from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';

class EnvioMail extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            asunto: "",
            mensaje: "",
            mensaje_envio: "",
            filtropozo: []
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.handleChangeMail = this.handleChangeMail.bind(this);
        this.handleChangeMensaje = this.handleChangeMensaje.bind(this);
        this.handleChangeAsunto = this.handleChangeAsunto.bind(this);
        this.enviarEmail = this.enviarEmail.bind(this);
        this.filtroPozo = this.filtroPozo.bind(this);
        this.handleChangePozo = this.handleChangePozo.bind(this);
    }
    componentDidMount() {
        this.validaLogin()
    }
    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            alert("Sesion no ha sido iniciada");
            //   window.location.href = "/"
        } else {
            this.filtroPozo();
        }
    }
    handleChangePozo(event) {
        event.preventDefault();
        var valor = event.target.value;
        if (valor !== '') {
            localStorage.setItem('codigo_pozo', valor);
        }
        var mensaje = "Estimado, \n Junto con saludar, se informa que el pozo código " + valor;
        if (this.state.asunto === -1) {
        } else if (this.state.asunto === "1") {
            mensaje = mensaje + " no cuenta con registros de aguas profundas hasta la fecha para el envio de datos correspondientes, solicito plazo para realizar los envios atrasados. \n\r  Saludos cordiales, \r " + localStorage.getItem('nombre_usuario') + " " + localStorage.getItem('apellido_usuario');
        } else if (this.state.asunto === "2") {
            mensaje = mensaje + " ha sufrido falla con lo sensores, por lo cual el envio de datos correspondientes estará pausado, solicito plazo para realizar los envios atrasados. \n\r  Saludos cordiales, \r " + localStorage.getItem('nombre_usuario') + " " + localStorage.getItem('apellido_usuario');

        }
        this.setState({ mensaje: mensaje });

    }

    filtroPozo() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/filtropozo.php', obj).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    this.setState({ filtropozo: response.data })
                    console.log(this.state.filtropozo);
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    handleChangeMail(event) {
        event.preventDefault();
        this.setState({ email: event.target.value });
    }
    handleChangeMensaje(event) {
        event.preventDefault();
        this.setState({ mensaje: event.target.value });
    }
    handleChangeAsunto(event) {
        event.preventDefault();
        var valor = event.target.value;
        this.setState({ asunto: valor });
        console.log(valor);
        var mensaje = "Estimado, \n\r Junto con saludar, se informa que el pozo código " + localStorage.getItem('codigo_pozo');
        if (valor === -1) {
        } else if (valor === "1") {
            mensaje = mensaje + " no cuenta con registros hasta la fecha para el envio de datos correspondientes, solicito plazo para realizar los envios atrasados. \n\r  Saludos cordiales, \r " + localStorage.getItem('nombre_usuario') + " " + localStorage.getItem('apellido_usuario');

        } else if (valor === "2") {
            mensaje = mensaje + " ha sufrido falla con lo sensores por lo cual el envio de datos correspondientes sera pausado, solicito plazo para realizar los envios atrasados. \n\r  Saludos cordiales, \r " + localStorage.getItem('nombre_usuario') + " " + localStorage.getItem('apellido_usuario');
        }
        this.setState({ mensaje: mensaje });
    }


    async enviarEmail(e) {
        e.preventDefault();
        const obj = { titulo: "Soporte Monitoreo de extracciones efectivas", email: localStorage.getItem('mail_usuario'), codigo_pozo: localStorage.getItem('codigo_pozo'), mensaje: this.state.mensaje, nombre: localStorage.getItem('nombre_usuario'), apellido: localStorage.getItem('apellido_usuario') };
        try {
            if (this.state.mensaje !== undefined) {
                axios.post('https://dga.sanjoseapps.cl/DGA/api/mail.php', obj);
                console.log("Envio realizado correctamente");
            }
            else {
                console.log("Envio incompleto");
            }
        } catch (error) {
        }
    }

    render() {
     
        return (
            <React.Fragment>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="form__form-group" style={{ "container": "wrap" }} >

                                <form onSubmit={this.enviarEmail} className="login__form active" id="register__form">
                                    <div className="row">
                                        <label className="form-label"> <h3 style={{ "margin": "2px 10px 6px 6px" }}> Nombre Pozo </h3></label>
                                        <Form.Group controlId="dob">

                                            <Form.Control as="select" defaultValue={-1} onChange={this.handleChangePozo}>
                                                <option key={-1} value={-1} > SELECCIONAR POZO </option>
                                                {this.state.filtropozo.map((r) => { return (< option key={r.codigo_pozo} value={r.codigo_pozo}> {r.codigo_pozo + " (" + r.nombre_pozo + "-" + r.nombre_campo + ")"} </option>) })}
                                            </Form.Control>
                                        </Form.Group>
                                        <label className="form-label">  <h3 style={{ "margin": "2px 10px 6px 6px" }}> Seleccionar Motivo</h3></label>
                                        <Form.Group controlId="dob">

                                            <Form.Control as="select" defaultValue={-1} onChange={this.handleChangeAsunto}>
                                                <option key={-1} value={-1} > SOLICITUD ABIERTA </option>
                                                <option key={1} value={1} > INFORMAR POZO SIN REGISTROS  </option>
                                                <option key={2} value={2} > FALLA DE SENSORES DEL POZO </option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                    <label className="form-label"><h3 style={{ "margin": "2px 10px 6px 6px" }}> Correo </h3></label>

                                    <textarea placeholder=" INGRESE SU MENSAJE " value={this.state.mensaje} style={{"width": "100%", "height": "300px", "text-align-last": "center"}} onChange={this.handleChangeMensaje} maxLength={250} required />
                                 
                           
                                    
                                 
                                    <div>
                                    <Button color="primary" type="submit">Enviar</Button>
           
                                    </div>
                                </form>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment >
        )
    }
}

export default EnvioMail;