import React from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

import { Card, CardBody, Col, Table } from 'reactstrap';

class AsignarPozo extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            idtbl_campo: 0,
            lista_campo: [],
            listaPozoAsignado:[],
            listaPozoSinAsignar:[],
            codigo_pozo:0
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.selectCampos = this.selectCampos.bind(this);
        this.traerTabla = this.traerTabla.bind(this);
        this.handleChangeCampo = this.handleChangeCampo.bind(this);
        this.handleChangePozo = this.handleChangePozo.bind(this);
        this.cellButton = this.cellButton.bind(this);
        this.asignarPozo = this.asignarPozo.bind(this);
        this.eliminarPozo = this.eliminarPozo.bind(this); 
    }
    componentDidMount() {
        this.validaLogin()
    }
    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            alert("Sesion no ha sido iniciada");
        } else {
            this.selectCampos();
        }
    }
    
    
    selectCampos() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/selectorCampo.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ lista_campo: response.data })
                    console.log(datos);
                } else {
                    //console.log("Error al obtener las regiones");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    handleChangeCampo(event){
        event.preventDefault();
        let campo =  event.target.value ;
        this.setState({idtbl_campo : campo})
        this.traerTabla(campo);
    }

   traerTabla(value){
    const obj = { id_campo :  value };
    console.log(this.state.idtbl_campo )
    try {
     axios.post('https://dga.sanjoseapps.cl/DGA/api/traerPozosAsignacion.php', obj).then(response => {
         const datos = response.data
         if (datos !== undefined) {
             let listaPozoSinAsignar = datos.filter(function (lista) { return lista.campo_id === '0'  });
             let listaPozoAsignado = datos.filter(function (lista) { return lista.campo_id !== '0'  });
             this.setState({ listaPozoAsignado: listaPozoAsignado})
             this.setState({ listaPozoSinAsignar: listaPozoSinAsignar })
           
         } else {
             //console.log("Error al obtener las regiones");
         }
     })
 } catch (error) {
     console.error(error);
 }

   }


    handleChangePozo(event){
        event.preventDefault();
        this.setState({ codigo_pozo :  event.target.value });
    
    }

    asignarPozo(event) {
        event.preventDefault();
        const obj = { idtbl_campo: this.state.idtbl_campo, codigo_pozo:this.state.codigo_pozo  };
        try {
            if (this.state.codigo_pozo !== undefined) {
                axios.post('https://dga.sanjoseapps.cl/DGA/api/asignarPozo.php', obj).then(response => {
                    const datos = response.data
                    if (datos !== undefined) {
                        alert("Pozo asignado")     
                        this.traerTabla( this.state.idtbl_campo);
                    } else {
                        //console.log("Error al obtener las regiones");
                }})
            }        
        } catch (error) {
            //console.log(error);
        }
    }

    eliminarPozo(row){
        const obj = { idtbl_campo: 0, codigo_pozo:row };
        try {
            if (this.state.codigo_pozo !== undefined) {
                axios.post('https://dga.sanjoseapps.cl/DGA/api/asignarPozo.php', obj).then(response => {
                    const datos = response.data
                    if (datos !== undefined) {
                        alert("Pozo desasignado del campo")    
                        this.traerTabla( this.state.idtbl_campo); 
                    } else {
                        //console.log("Error al obtener las regiones");
                }})
            }        
        } catch (error) {
            //console.log(error);
        }
    }


    cellButton(row) {
        let theButton;
        theButton = <Button variant="success" style={{"background": "#6d9934"}}  onClick={() => this.eliminarPozo(row)}> Eliminar Asignación Pozo   </Button>
        return theButton;
    }
 
    render() {
        return (
            <React.Fragment>
           <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text">En esta pestaña podra asignar pozos a un campo</h5>
                                </div>
                              <form onSubmit={this.asignarPozo} className="login__form active" id="register__form">
                                    <div className="row">
                                        <div className="col-md-6" style={{"margin-left": "0"}}>
                                            <div>
                                             <h4>   NOMBRE DEL CAMPO </h4>
                                            </div>
                                            <div>
                                             <Form.Control as="select" id={"1"} onChange={this.handleChangeCampo}>
                                                    <option key={0} value={-1}  >SELECCIONE CAMPO </option>
                                                    {this.state.lista_campo.map((r) => {
                                                        return (< option key={r.id} value={r.id}> {r.nombre_campo} </option>)
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{"margin-left": "0"}}>
                                            <div>
                                             <h4> POZO SIN ASIGNAR </h4>
                                            </div>
                                            <div>
                                             <Form.Control as="select" id={"1"} onChange={this.handleChangePozo}>
                                                    <option key={0} value={-1}  >SELECCIONE POZO </option>
                                                    {this.state.listaPozoSinAsignar.map((r) => {
                                                        return (< option key={r.id} value={r.codigo_pozo}> {r.nombre_pozo} </option>)
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{"margin-left": "0"}}>
                                            <div>
                                            <Form.Control type="submit" value="Asignar Pozo " />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                 
                                    <br></br>
                                    <div className="table" style={{ width: "90%", "margin": "auto", "fontSize": "0.9rem" }} >
                                                   
                                                   <Table responsive className="table--bordered">
                                                       <thead>
                                                           <tr>
                                                               <th key={12}> <h5>CODIGO DE LA OBRA  </h5>  </th>
                                                               <th key={13}> <h5>NOMBRE POZO </h5> </th>                                                  
                                                               <th key={23}> <h5>ACCIONES </h5> </th>
                                                           </tr>
                                                       </thead>
                                                       <tbody>
                                                           {this.state.listaPozoAsignado.map(item => (
                                                               <tr key={item.id}>
                                                                   <td><h5> {item.codigo_pozo}</h5></td>
                                                                   <td><h5>{item.nombre_pozo}</h5></td>
                                                                   <td><h5>{this.cellButton(item.codigo_pozo)}</h5></td>
                                                               </tr>
                                                           ))}
                                                       </tbody>
                                                   </Table>
                                               </div>

                         
                    </CardBody>
                 </Card>
            </Col>
                       
            </React.Fragment >
        )
    }
}

export default AsignarPozo;