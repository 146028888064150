import React from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import { Card, CardBody, Col, Table, Badge } from 'reactstrap';
import axios from 'axios';
import loading from '../../img/loading.gif';


class BusquedaPozo extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            tablaHome: [],
            idtbl_campo: 0,
            alertEnvioExitoso: false,
            filtro_material: [],
            codigodelaobra: "",
            nombrePozo: "",
            filtrocampo: [],
            volumen_inscrito: '',
            datostabla: [],
            isLoading: false,
            pozos: [],
            cantidadEspecies: 1,
            esfiltrado: false,
            codigo_pozo: '',
            filtro: '',
            tablaSinFiltrar: [],
            fechaMinima: '2020-12-01',
            fechaMaxima: '2030-12-30',
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.obtenerTabla = this.obtenerTabla.bind(this);
        this.handleChangeCodigoDropBox = this.handleChangeCodigoDropBox.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCampo = this.handleChangeCampo.bind(this);
        this.handleChangeFechaDesde = this.handleChangeFechaDesde.bind(this);
        this.handleChangeFechaHasta = this.handleChangeFechaHasta.bind(this);
        this.obtenerTablavalor = this.obtenerTablavalor.bind(this);
        
        this.cellCaudalEficiencia = this.cellCaudalEficiencia.bind(this);
        this.codigosPozos = this.codigosPozos.bind(this);
        this.handleChangeEstado = this.handleChangeEstado.bind(this);
    }

    componentDidMount() {
        this.validaLogin()
        this.setState({ fechaMaxima: localStorage.getItem('fechaServidor') })

    }

    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            window.location.href = "/"
        }
        else {
            this.filtroCampo(localStorage.getItem('idtbl_usuario'));
            this.codigosPozos();
        }
    }
  
    cellEnviados(value) {
        let theButton;
        if (value === 'ENVIO PENDIENTE') {
            theButton = <Badge    style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#ea913b" }}  pill variant="warning"> Pendiente de Aprobación </Badge>
        } else if (value === 'ENVIADO') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#95b964"}} pill variant="success"> Enviado  </Badge>
        } else if (value === 'EN PROCESO') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#ea913b" }} pill variant="primary"> Proceso de envio </Badge>
        } else if (value === 'NO HAY REGISTRO') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color":"#ce4552"}} pill variant="danger"> No hay registros </Badge>
        }
        return theButton;
    }


    cellCaudalEficiencia(value) {
        let theButton;
        if (value !== 0) {
            var caudal_maximo = this.state.tablaSinFiltrar[0].lts_segundo
            var total = Math.round((value * 100) / caudal_maximo);
            if (total > 100) {
                theButton = <ProgressBar animated striped variant="danger" now={total} label={`${total}%`} />
            } else if (total < 50) {
                theButton = <ProgressBar animated striped variant="warning" now={total} label={`${total}%`} />
            } else {
                theButton = <ProgressBar animated striped variant="success" now={total} label={`${total}%`} />
            }
        } else {
            theButton = <ProgressBar animated striped variant="danger" now={0} label={`${0}%`} />
        }
        return theButton;
    }

    codigosPozos() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/codigosPozoAsignados.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ pozos: response.data })
                    this.setState({ codigo_pozo: datos[0].codigo_pozo})
                    this.obtenerTabla()
                }
            })
        }
        catch (error) {
            console.error(error);
        }
    }

    handleChange(event) {
        event.preventDefault();
        this.obtenerTabla(event.target.value);
        this.filtroCampo(event.target.value);
    }

    filtroCampo() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/filtrocampo.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ filtrocampo: response.data })
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    obtenerTabla() {
        const obj = { codigo_pozo: this.state.codigo_pozo };
        try {
            this.setState({ isLoading: true })
            axios.post('https://dga.sanjoseapps.cl/DGA/api/tablePozo.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ tablaSinFiltrar: response.data })
                    this.setState({ tablaHome: datos })
                    this.setState({ isLoading: false })
                }
            })
        }
        catch (error) {
            console.error(error);
        }
    }

    obtenerTablavalor(value) {
        const obj = { codigo_pozo: value };
        try {
            this.setState({ isLoading: true })
            axios.post('https://dga.sanjoseapps.cl/DGA/api/tablePozo.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ tablaSinFiltrar: response.data })
                    this.setState({ tablaHome: datos })
                    this.setState({ isLoading: false })
             
                }
            })
        } catch (error) {
            console.error(error);
        }
    }


    handleChangeCampo(event) {
        event.preventDefault();
        var valor = event.target.value;
        let tabla = this.state.tablaSinFiltrar;
        if (valor !== '') {
            let array = tabla.filter(function (campo) {  return campo.nombre_campo === valor  });
            this.setState({ tablaHome: array })
        } else {
            this.setState({ tablaHome: tabla })
        }
    }

    handleChangeFechaDesde(event) {
        event.preventDefault();
        var valorDesde = event.target.value;
        var valorHasta = this.state.fechaMaxima
        let tabla = this.state.tablaSinFiltrar;
        if (valorDesde !== '') {
            let array = tabla.filter(function (lista) {  return ((lista.timeStampOrigen >= valorDesde))  });
            array = array.filter(function (lista) {  return ((lista.timeStampOrigen <= valorHasta))  });
            this.setState({ tablaHome: array })
        } else {
            this.setState({ tablaHome: tabla })
        }
    }

    handleChangeFechaHasta(event) {
        event.preventDefault();
        var valorDesde = this.state.fechaMinima;
        var valorHasta = event.target.value;
        let tabla = this.state.tablaSinFiltrar;
        if (valorHasta !== '') {
            let array = tabla.filter(function (lista) {  return ((lista.timeStampOrigen >= valorDesde))  });
            array = array.filter(function (lista) {  return ((lista.timeStampOrigen <= valorHasta))  });
            this.setState({ tablaHome: array })
        } else {
            this.setState({ tablaHome: tabla })
        }
    }

    handleChangeEstadoEnviado(event, codigodelaobra, nombrePozo) {
        this.setState({ nombrePozo: nombrePozo, codigodelaobra: codigodelaobra, alertEnvioExitoso: true })
    }

    handleChangeEstado(event) {
        event.preventDefault();
        var valor = event.target.value;
        let tabla = this.state.tablaSinFiltrar;
        if (valor === 'on') {
            let array = tabla.filter(function (lista) {  return lista.enviado === valor });
            this.setState({ tablaHome: array })
        } else {
            this.setState({ tablaHome: tabla })
        }
    }
    handleChangeCodigoDropBox(event) {
        event.preventDefault();
        var valor = event.target.value;
        if (valor !== -1) {
            this.setState({ codigo_pozo: valor });
        }
        this.obtenerTablavalor(valor);
    }

    render() {
        return (
            <React.Fragment>
                  <Col md={12} lg={12}>
      <Card>
        <CardBody>       
              <div className="card__title">
                                <h5 className="bold-text">En esta pestaña podrá buscar información detallada sobre los registros de cada pozo.</h5>
                            </div>

                        <div className="form__form-group" style={{ "container": "wrap" }} >
                                <div className="row">
                                        <label className="form-label">  <h3 style={{ "margin": "2px 10px 6px 6px" }}>  Pozo</h3></label>
                                        <Form.Group controlId="dob">
                         
                                        <Form.Control as="select" id={"2"}  onChange={this.handleChangeCodigoDropBox} >
                                            {this.state.pozos.map((r) => {
                                                return (< option key={r.id} value={r.codigo_pozo}> {  r.nombre_campo  + " - "+  r.nombre_pozo + " (" + r.codigo_pozo + ")"} </option>)
                                            })}
                                        </Form.Control>
                                        </Form.Group>
                         
                                        <label className="form-label"> <h3 style={{ "margin": "2px 10px 6px 6px" }}> Fecha desde</h3></label>
                                        <Form.Group controlId="dob">
                                            <Form.Control type="date" defaultValue={'2020-12-01'} max={localStorage.getItem('fechaServidor')} min='2020-12-01' name="dob" onChange={this.handleChangeFechaDesde} />
                                        </Form.Group>
                                        <label className="form-label"> <h3 style={{ "margin": "2px 10px 6px 6px" }}> Fecha hasta</h3></label>

                                        <Form.Group controlId="dob">
                                            <Form.Control type="date" name="dob" defaultValue={localStorage.getItem('fechaServidor')} max={localStorage.getItem('fechaServidor')} min='2020-12-01' onChange={this.handleChangeFechaHasta} />
                                        </Form.Group>
                                    </div>
                          </div>
                        {
                            this.state.isLoading === true ?
                                <section className="table table-responsive mt-md-5">
                                    <div className="container table">
                                        <div align="center"><img src={loading} style={{"width":"10%" }} alt="loading..." /></div>
                                    </div>
                                </section>
                                :
                                <section className="table table-responsive mt-md-5">
                                    <div className="table" style={{ width: "80%", "margin": "auto" }} >
                            
                                            <Table responsive className="table--bordered">
                                                        <thead>
                                                            <tr>
                                                                <th  style={{"vertical-align": "initial"}}  key={1}> <h5>FECHA MEDICIÓN  </h5>  </th>
                                                                <th  style={{"vertical-align": "initial"}}  key={2}> <h5>HORA MEDICIÓN </h5> </th>
                                                                <th  style={{"vertical-align": "initial"}}  key={3}> <h5>CAUDAL REGISTRADO(l/s)</h5> </th>
                                                                <th  style={{"vertical-align": "initial"}} key={6}> <h5>TOTALIZADOR (m³) </h5> </th>
                                                                <th  style={{"vertical-align": "initial"}}  key={7}> <h5>NIVEL FREATICO (mts)  </h5></th>
                                                                <th  style={{"vertical-align": "initial"}} key={9}> <h5>ENVIO  </h5></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.tablaHome.map(item => (
                                                                <tr key={item.id}>
                                                                    <td><h5> {item.fechaMedicion}</h5></td>
                                                                    <td><h5>{item.horaMedicion}</h5></td>
                                                                    <td><h5>{item.caudal}</h5></td>
                                                                     <td><h5>{item.totalizador}</h5></td>
                                                                    <td><h5>{item.nivelFreaticodelpozo}</h5></td>
                                                                    <td><h5>{this.cellEnviados(item.enviado)}</h5></td>
  
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                          </div>
                                </section>
                        }
                    </CardBody>
      </Card>
    </Col>
            </React.Fragment >
        )
    }
}

export default BusquedaPozo;