import React from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';

class RegistroCampo extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            idtbl_campo: 0,
            filtrousuario: [],
            regiones:[],
            comunas:[],
            provincias:[],
            comunasFiltradas:[],
            provinciasFiltradas:[],
            nombre: '',
            region:'',
            comuna:'',
            provincia:'',
            usuario: '',
            esfiltrado: false,
            filtro: '',
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.handleChangeRegion = this.handleChangeRegion.bind(this);
        this.handleChangeProvincia = this.handleChangeProvincia.bind(this);
        this.registrarCampo = this.registrarCampo.bind(this);
        this.selectProvincias = this.selectProvincias.bind(this);
        this.selectRegiones = this.selectRegiones.bind(this);
        this.selectComunas = this.selectComunas.bind(this);
        this.handleChangeComuna = this.handleChangeComuna.bind(this);
    }
    componentDidMount() {
        this.validaLogin()
    }
    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            alert("Sesion no ha sido iniciada");
        } else {
            this.selectRegiones();
            this.selectProvincias();
            this.selectComunas();
        }
    }
    
    handleChangeProvincia(event){
        event.preventDefault();    
        let valor = event.target.value;
        let tabla = this.state.comunas;
        if (valor !== '') {
            let array = tabla.filter(function (campo) {  return campo.provincia_id === valor  });
            this.setState({ comunasFiltradas: array })
        } else {
            this.setState({ comunasFiltradas: tabla })
        }
        this.setState({provincia:valor})
    }

    
    handleChangeRegion(event){
        event.preventDefault();    
        let valor = event.target.value;
        let tabla = this.state.provincias;
        if (valor !== '') {
            let array = tabla.filter(function (campo) {  return campo.region_id === valor  });
            this.setState({ provinciasFiltradas: array })
        } else {
            this.setState({ provinciasFiltradas: tabla })
        }
        this.setState({region:valor})
    }
    
    handleChangeComuna(event){
        event.preventDefault();    
        let valor = event.target.value;
        this.setState({comuna:valor})
    }

    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({ nombre: event.target.value });
    }
    selectRegiones() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/selectorRegiones.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ regiones: response.data })

                } else {
                    //console.log("Error al obtener las regiones");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    selectComunas() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/selectorComunas.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ comunas: response.data })
                    this.setState({ comunasFiltradas: response.data })
                } else {
                    //console.log("Error al obtener las regiones");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }



    selectProvincias() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/selectorProvincias.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ provincias: response.data })
                    this.setState({ provinciasFiltradas: response.data })
                } else {
                    //console.log("Error al obtener las regiones");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    registrarCampo(event) {
        event.preventDefault();
        const obj = { nombre: this.state.nombre, region:this.state.region, comuna:this.state.comuna, provincia: this.state.provincia };
        try {
            if (this.state.nombre !== undefined) {
                axios.post('https://dga.sanjoseapps.cl/DGA/api/registrarCampo.php', obj)
            } else {
                alert("Ingresar Nombre Campo");
            }        
        } catch (error) {
            //console.log(error);
        }
    }

    render() {
        return (
            <React.Fragment>
                
       
       <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text">En esta pestaña podra agregar un campo al sistema</h5>
                                </div>
               
                              <form onSubmit={this.registrarCampo} className="login__form active" id="register__form">
                                    <div className="row">
                                        <div className="col-md-6" style={{"margin-left": "0"}}>
                                            <div>
                                             <h4>   NOMBRE DEL CAMPO </h4>
                                            </div>
                                            <div>
                                            <Form.Control type="text" placeholder="Ingrese el nombre del Campo" value={this.state.nombre} onChange={this.handleChangeNombre} maxLength={45} required />
                               
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{"margin-left": "0"}}>
                                            <div>
                                                <h4>   REGION</h4>
                                            </div>
                                            <div>
                                                <Form.Control as="select" id={"1"} onChange={this.handleChangeRegion}>
                                                    <option key={0} value={0}  >SELECCIONE REGION </option>
                                                    {this.state.regiones.map((r) => {
                                                        return (< option key={r.id} value={r.id}> {r.region} </option>)
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{"margin-left": "0"}}>
                                            <div>
                                             <h4>  PROVINCIA </h4>
                                            </div>
                                            <div>
                                                <Form.Control as="select" id={"1"}  onChange={this.handleChangeProvincia}>
                                                    <option key={0} value={0}  >SELECCIONE PROVINCIA </option>
                                                    {this.state.provinciasFiltradas.map((r) => {
                                                        return (< option key={r.id} value={r.id}> {r.provincia} </option>)
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                            
                                    <div className="row">
                                        <div className="col-md-6" style={{"margin-left": "0"}}>
                                            <div>
                                             <h4>   COMUNA </h4>
                                            </div>
                                            <div>
                                                <Form.Control as="select" id={"1"}  onChange={this.handleChangeComuna}>
                                                    <option key={0} value={0}  >SELECCIONE COMUNA </option>
                                                    {this.state.comunasFiltradas.map((r) => {
                                                        return (< option key={r.id} value={r.id}> {r.comuna} </option>)
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </div>
                             <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{"margin-left": "0"}}>
                                            <div>
                                            <Form.Control type="submit" value="Registrar Campo" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                    </CardBody>
                 </Card>
            </Col>
                       
            </React.Fragment >
        )
    }
}

export default RegistroCampo;