import React from 'react';
import { Form, Button, Tabs, Tab, Badge} from 'react-bootstrap';
import { reduxForm, formValueSelector } from 'redux-form';
import axios from 'axios';
import icon_informacion from '../../img/informacion.png'
import loading from '../../img/loading.gif';
import { Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux'
import { Card, CardBody, Col, Table, Alert, UncontrolledPopover,PopoverHeader,PopoverBody, Fade} from 'reactstrap';


class Bandeja_entrada extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tablaHomeEnviados: [],
            tablaHomeNoEnviados: [],
            posicion_tab: "1",
            idtbl_campo: 0,
            alertEnvioExitoso: false,
            filtro_material: [],
            codigodelaobra: "",
            nombrePozo: "",
            filtropozo: [],
            filtrocampo: [],
            tablaFaltantes: [],
            lista_pozo: [],
            lista_caudal: [],
            tablaBase: [],
            lista_caudalMaximo: [],
            datostabla: [],
            navigate: "",
            mostrarModalInformacion:false,
            isLoading: false,
            cantidadEspecies: 1,
            esfiltrado: false,
            filtro: '',
            contadorNotificaciones: 0,
            tablaSinFiltrar: [],
            usuarioAutorizado: false,
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.aprobacionPozo = this.aprobacionPozo.bind(this);
        this.obtenerTabla = this.obtenerTabla.bind(this);
        this.cambioTab = this.cambioTab.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCampo = this.handleChangeCampo.bind(this);
        this.handleChangeFechaDesde = this.handleChangeFechaDesde.bind(this);
        this.handleChangeFechaHasta = this.handleChangeFechaHasta.bind(this);
        this.cellButton = this.cellButton.bind(this);
        this.obtenerTablaFaltantes = this.obtenerTablaFaltantes.bind(this);
        this.filtroCampo = this.filtroCampo.bind(this);
        this.cellEnviados = this.cellEnviados.bind(this);
        this.datosPozo = this.datosPozo.bind(this);
        this.handleChangeNombrePozo = this.handleChangeNombrePozo.bind(this);
        this.revisionPozo = this.revisionPozo.bind(this);
        this.obtenerTablaFaltantes = this.obtenerTablaFaltantes.bind(this);
        this.cellEstado = this.cellEstado.bind(this);
    }
    componentDidMount() {
        this.validaLogin()
    }

    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            this.setState({ usuarioAutorizado: false });
        } else {
            this.setState({ usuarioAutorizado: true });
            this.obtenerTabla(0);
            this.obtenerTablaFaltantes();
            this.filtroCampo(localStorage.getItem('idtbl_usuario'));
        }
    }

    handleChange(event) {
        event.preventDefault();
        this.obtenerTabla(event.target.value);
        this.filtroCampo(localStorage.getItem('idtbl_usuario'))
    }

    filtroCampo() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            axios.post('https://dga.sanjoseapps.cl/DGA/api/filtrocampo.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ filtrocampo: response.data })
                }
            })
        } catch (error) {
            //console.error(error);
        }
    }

    obtenerTablaFaltantes() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            let valor = 0;
            this.setState({ isLoading: true })
            axios.post('https://dga.sanjoseapps.cl/DGA/api/tablaFaltantes.php', obj).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    this.setState({ tablaFaltantes: datos })
                    datos.map((item) => {
                        if (item.alerta === "1") {
                            valor = valor + 1;
                        }   
                    })
                    this.setState({ contadorNotificaciones: valor })
                }
            });
        } catch (error) {
            //console.error(error);
        }
    }

    obtenerTabla(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
            this.setState({ isLoading: true })
            axios.post('https://dga.sanjoseapps.cl/DGA/api/tablahome.php', obj).then(response => {
                const datos = response.data
                   if (datos !== undefined) {
                    let arrayEnviado = datos.filter(function (lista) { return lista.enviado === 'ENVIADO' } );
                    let arrayNoEnviado = datos.filter(function (lista) {  return lista.enviado !== 'ENVIADO'  });
                 
                    let filtroPozo = datos.filter(function (lista) {  return lista.nombre_pozo  })
                    const lista_pozo = datos.map((item) => item.nombre_pozo);
                    const lista_volumen = datos.map((item) => item.volumen_inscrito_anual);
                    const lista_caudal = datos.map((item) => parseFloat(item.caudal));
                    const lista_caudalMaximo = datos.map((item) => parseFloat(item.lts_segundo));
                    const lista_totalizador = datos.map((item) => item.totalizador);
                    this.setState({ lista_totalizador: lista_totalizador })
                    this.setState({ lista_caudalMaximo: lista_caudalMaximo })
                    this.setState({ lista_pozo: lista_pozo })
                    this.setState({ lista_caudal: lista_caudal })
                    this.setState({ lista_volumen: lista_volumen })
                    this.setState({ filtropozo: filtroPozo })
                    this.setState({ tablaBase: datos });
                    this.setState({ tablaHomeEnviados: arrayEnviado })
                    this.setState({ tablaHomeNoEnviados: arrayNoEnviado })
                    this.setState({ tablaEnviadosSinFiltrar: arrayEnviado })
                    this.setState({ tablaNoEnviadosSinFiltrar: arrayNoEnviado })
                    this.setState({ isLoading: false })
                }
            })
            this.obtenerTablaFaltantes();
        } catch (error) {
            //console.error(error);
        }
    }   

    cambioTab() {
        if (this.state.posicion_tab === "1") {
            const datos = this.state.tablaHomeEnviados;
            let filtroPozo = datos.filter(function (lista) {  return lista.nombre_pozo  });
            this.setState({ filtropozo: filtroPozo });
            this.setState({ posicion_tab: "0" });
        }
        else
        {
            const datos = this.state.tablaHomeNoEnviados;
            let filtroPozo = datos.filter(function (lista) {  return lista.nombre_pozo  });
            this.setState({ filtropozo: filtroPozo });
            this.setState({ posicion_tab: "1" });
        }
    }

    handleChangeCampo(event) {
        event.preventDefault();
        var valor = event.target.value;
        let tabla = this.state.tablaEnviadosSinFiltrar;
        if (valor !== '') {
            let array = tabla.filter(function (campo) {  return campo.nombre_campo === valor  });
            this.setState({ tablaHomeEnviados: array })
     
            let filtroPozo = array.filter(function (lista) {  return lista.nombre_pozo  });   
            this.setState({ filtropozo: filtroPozo });
        } else {
            this.setState({ tablaHomeEnviados: tabla })
     
        }
        let tablaNoEnviados = this.state.tablaNoEnviadosSinFiltrar;
        if (valor !== '') {
            let arrayNoEnviados = tablaNoEnviados.filter(function (campo) {  return campo.nombre_campo === valor  });
            this.setState({ tablaHomeNoEnviados: arrayNoEnviados })
            
            let filtroPozo = arrayNoEnviados.filter(function (lista) {  return lista.nombre_pozo  });   
            this.setState({ filtropozo: filtroPozo });
        } else {
            this.setState({ tablaHomeNoEnviados: tablaNoEnviados })
        }
    }

    handleChangeNombrePozo(event) {
        event.preventDefault();
        var valor = event.target.value;
        let tabla = this.state.tablaEnviadosSinFiltrar;
        if (valor !== '') {
            let array = tabla.filter(function (campo) { return campo.nombre_pozo === valor });
            this.setState({ tablaHomeEnviados: array })
        } else {
            this.setState({ tablaHomeEnviados: tabla })
        }
        let tablaNoEnviados = this.state.tablaNoEnviadosSinFiltrar;
        if (valor !== '') {
            let arrayNoEnviados = tablaNoEnviados.filter(function (campo) {  return campo.nombre_pozo === valor  });
            this.setState({ tablaHomeNoEnviados: arrayNoEnviados })
        } else {
            this.setState({ tablaHomeNoEnviados: tablaNoEnviados })
        }
    }

    handleChangeFechaDesde(event) {
        event.preventDefault();
        var valor = event.target.value;
        let tabla = this.state.tablaEnviadosSinFiltrar;
        if (valor !== '') {
            let array = tabla.filter(function (lista) {  return lista.timeStampOrigen > valor  });
            this.setState({ tablaHomeEnviados: array })
        } else {
            this.setState({ tablaHomeEnviados: tabla })
        }
        let tablaNoEnviados = this.state.tablaNoEnviadosSinFiltrar;
        if (valor !== '') {
            let arrayNoEnviados = tablaNoEnviados.filter(function (lista) {  return lista.timeStampOrigen > valor  });
            this.setState({ tablaHomeNoEnviados: arrayNoEnviados })
        } else {
            this.setState({ tablaHomeNoEnviados: tablaNoEnviados })
        }
    }

    handleChangeFechaHasta(event) {
        event.preventDefault();
        var valor = event.target.value;
        let tabla = this.state.tablaEnviadosSinFiltrar;
        if (valor !== '') {
            let array = tabla.filter(function (lista) {  return lista.timeStampOrigen < valor });
            this.setState({ tablaHomeEnviados: array })
        } else {
            this.setState({ tablaHomeEnviados: tabla })
        }
        let tablaNoEnviados = this.state.tablaNoEnviadosSinFiltrar;
        if (valor !== '') {
            let arrayNoEnviados = tablaNoEnviados.filter(function (lista) { return lista.timeStampOrigen < valor } );
            this.setState({ tablaHomeNoEnviados: arrayNoEnviados })
        } else {
            this.setState({ tablaHomeNoEnviados: tablaNoEnviados })
        }
    }

    revisionPozo(value) {
        localStorage.setItem('codigo_pozo', value);
        this.setState({ navigate: true });
    }

    aprobacionPozo(value) {
        localStorage.setItem('codigo_pozo', value);
        this.setState({ navigate: false });
    }

    datosPozo(event, codigodelaobra) {
        event.preventDefault();
        localStorage.setItem('codigo_pozo', codigodelaobra);
        this.setState({ navigate: true });
    }

    handleChangeEstado(event) {
        event.preventDefault();
        var valor = event.target.value;
        let tabla = this.state.tablaSinFiltrar;
        if (valor === 'on') {
            let array = tabla.filter(function (lista) {  return lista.enviado === valor });
            this.setState({ tablahome: array })
        } else {
            this.setState({ tablahome: tabla })
        }
    }

    cellButton(row) {
        let theButton;
        theButton = <Button variant="success" style={{"background": "#6d9934"}}  onClick={() => this.aprobacionPozo(row)}> Aprobar   </Button>
        return theButton;
    }

    cellEstado(row){
        let theButton;
        if (row === '0') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#95b964"}} pill variant="success"> Transmitiendo </Badge>
        } else if (row === '1') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#95b964"}} pill variant="success"> Transmitiendo  </Badge>
        } else if (row === '2') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#ea913b"}} pill variant="primary"> Retraso </Badge>
        } else if (row === 'NO HAY REGISTRO') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color":"#ce4552"}} pill variant="danger"> No hay registros </Badge>
        }
        return theButton;
    }

    cellEnviados(row) {
        let theButton;
        if (row === 'ENVIO PENDIENTE') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#ea913b" }} pill variant="warning"> Pendiente de Aprobación </Badge>
        } else if (row === 'ENVIADO') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#95b964"}} pill variant="success"> Enviado  </Badge>
        } else if (row === 'EN PROCESO') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#95b964"}} pill variant="primary"> Proceso de envio </Badge>
        } else if (row === 'NO HAY REGISTRO') {
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color":"#ce4552"}} pill variant="danger"> No hay registros </Badge>
        }
        return theButton;
    }

    render() {
        var login = localStorage.getItem('idtbl_usuario');
        if (login === null || login === "undefined" )  {
            return <Redirect to="/" push={true} />         
        } else { 
        if (this.state.navigate === true) {
            return <Redirect to="/revisionPozo" push={true} />
        } else if (this.state.navigate === false) {
            return <Redirect to="/aprobar" push={true} />
        } else {
            return (
                <React.Fragment>
                    <Col md={12} lg={12}>
                        <Card>    
                            {
                                    this.state.isLoading === true ?
                                   
                                     ( <section className="table table-responsive mt-md-5">
                                            <div className="container table">
                                                <div align="center"> 
                                            <Spinner type="grow" color="success" /> </div>
                                            
                                            </div>
                                        </section> )
                                        :                                          
                            (
                            <Fade in={true} tag="h5" className="mt-3" transition={{in: true, timeout: 750}} >
                             <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text"> Resumen de últimos datos sobre el consumo de agua de sus campos registrados en el sistema</h5>
                                 </div>
                                    <div className="form__form-group" style={{ "container": "wrap" }} >
                                        <div className="row">
                                            <label className="form-label"><h3 style={{ "margin": "2px 10px 6px 6px" }}> Campo </h3></label>
                                            <Form.Group >
                                                <Form.Control as="select"  defaultValue={''} id={"1"} onChange={this.handleChangeCampo} >
                                                    
                                                    <option value={''} > TODOS LOS CAMPOS</option>
                                                    { //SELECCION MULTIPLE
                                                     this.state.filtrocampo.map((r) => {
                                                        return (< option key={r.codigodelaobra} value={r.nombre_campo}> {r.nombre_campo} </option>)
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                            <label className="form-label"><h3 style={{ "margin": "2px 10px 6px 6px" }} >Pozo</h3></label>
                                            <Form.Group >
                                                <Form.Control as="select" defaultValue={''} id={"1"} onChange={this.handleChangeNombrePozo}>
                                                    <option value={''} > TODOS LOS POZOS</option>
                                                    {this.state.filtropozo.map((r) => {
                                                        return (< option key={r.codigodelaobra} value={r.nombre_pozo}> {r.nombre_pozo} </option>)
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                          </div>
                

                                        
                                          <Alert color="info" transition={{in: true, timeout: 150}} className="alert--bordered" icon>
                                            <p style={{"color":"black"}}> 
                                                <span>  <h3 style={{"color":"black"}}   className="bold-text"> Última actualización </h3> </span> Los datos mostrados en este informe corresponde a las mediciones de las últimas 24 horas.
                                            </p>    
                                          </Alert>    





                                <Tabs defaultActiveKey="NoEnviados" id="uncontrolled-tab-example" onClick={() => this.cambioTab()}  >
                                                <Tab eventKey="NoEnviados" title="Pendientes de Aprobación">
                                                <UncontrolledPopover trigger="focus" target="PopperTotalizador" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Totalizador?</PopoverHeader>
                                                    <PopoverBody> Es el valor del flujómetro que permita medir el volumen de agua extraído.</PopoverBody>
                                                </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="CaudalInscrito" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Caudal Inscrito?</PopoverHeader>
                                                    <PopoverBody>Es el nivel máximo de extracción de agua por segundo designado al pozo por la D.G.A.</PopoverBody>
                                                </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="PromCaudal" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Promedio Caudal?</PopoverHeader>
                                                    <PopoverBody>Es el promedio de las últimas 24 horas del caudal extraido registrado por el pozo.</PopoverBody>
                                                </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="NivelFreatico" placement="bottom" >                                     
     
                                                    <PopoverHeader>¿Qué es el Nivel Freático?</PopoverHeader>
                                                    <PopoverBody> Es la distancia desde el nivel del suelo a la superficie del agua.</PopoverBody>
                                                </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="Standard" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Standard?</PopoverHeader>
                                                    <PopoverBody> Indica la frecuencia con la cual se deben informar los datos. </PopoverBody>
                                                </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="TiempoUltimoEnvio" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Tiempo último envio?</PopoverHeader>
                                                    <PopoverBody>Es la cantidad de tiempo trancurrido desde el último envio a la D.G.A.</PopoverBody>
                                                 </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="Envio" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Estado de Recepción?</PopoverHeader>
                                                    <PopoverBody> Es el estado actual en el que se encuentran los datos recepcionados dispuestos a ser enviados.</PopoverBody>
                                                </UncontrolledPopover>  
                                                <UncontrolledPopover trigger="focus" target="Estado" placement="bottom" >                                     
                                                    <PopoverHeader>¿Qué es el Estado Envio ?</PopoverHeader>
                                                    <PopoverBody>Nos indica el nivel de retraso correspondiente entre la fecha actual y los datos recepcionados de los pozos.</PopoverBody>
                                                </UncontrolledPopover>                                    
                                                    <div className="table" style={{ width: "100%", "margin": "auto", "fontSize": "0.7rem" }} >
                                                        <Table hover striped responsive className="table--bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{"vertical-align": "initial"}} key={1}> <h5>CAMPO  </h5>  </th>
                                                                    <th style={{"vertical-align": "initial"}} key={2}> <h5>POZO </h5> </th>
                                                                    <th style={{"vertical-align": "initial"}} key={3}> <h5>TOTALIZADOR (m³) <button style={{"border-width":"0", "background":"none"}}  id="PopperTotalizador" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>  </h5> </th>          
                                                                    <th style={{"vertical-align": "initial"}} key={4}> <h5>CAUDAL INSCRITO (l/s) <button style={{"border-width":"0", "background":"none"}}  id="CaudalInscrito" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>   </h5> </th>
                                                                    <th style={{"vertical-align": "initial"}} key={5}> <h5>PROMEDIO CAUDAL (l/s) <button style={{"border-width":"0", "background":"none"}}  id="PromCaudal" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>  </h5> </th>                                                                  
                                                                    <th style={{"vertical-align": "initial"}} key={6}> <h5>NIVEL FREÁTICO (mts) <button style={{"border-width":"0", "background":"none"}}  id="NivelFreatico" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>  </h5> </th>                                                                  
                                                                    <th style={{"vertical-align": "initial"}} key={7}> <h5>STANDARD DE POZO <button style={{"border-width":"0", "background":"none"}}  id="Standard" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>  </h5></th>
                                                                    <th style={{"vertical-align": "initial"}} key={8}> <h5>TIEMPO ÚLTIMO ENVIO <button style={{"border-width":"0", "background":"none"}}  id="TiempoUltimoEnvio" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>  </h5></th>
                                                                    <th style={{"vertical-align": "initial"}} key={9}> <h5>ESTADO RECEPCION <button style={{"border-width":"0", "background":"none"}}  id="Envio" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>  </h5></th>
                                                                    <th style={{"vertical-align": "initial"}} key={10}> <h5>ESTADO ENVIO <button style={{"border-width":"0", "background":"none"}}  id="Estado" type="button" ><img src={icon_informacion} style={{'width': '20px'}} alt="" /></button>   </h5></th>
                                                                    <th style={{"vertical-align": "initial"}} key={11}> <h5>ACCIONES </h5> </th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {this.state.tablaHomeNoEnviados.map(item => (
                                                                    <tr key={item.codigoDeLaObra}>
                                                                        <td><h5> {item.nombre_campo}</h5></td>
                                                                        <td><h5>{item.nombre_pozo}</h5></td>
                                                                        <td><h5>{item.totalizador}</h5></td>
                                                                        <td><h5>{item.lts_segundo}</h5></td>
                                                                        <td><h5>{item.caudal}</h5></td>
                                                                        <td><h5>{item.nivelFreaticodelpozo}</h5></td>
                                                                        <td><h5>{item.standard}</h5></td>
                                                                        <td><h5>{item.horas}</h5></td>
                                                                        <td><h5>{this.cellEnviados(item.enviado)}</h5></td>
                                                                        <td><h5>{this.cellEstado(item.alerta)}</h5></td>
                                                                        <td><h5>{this.cellButton(item.codigoDeLaObra)}</h5></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="Enviados" title="Enviados">
                                                    <div className="table" style={{ width: "90%", "margin": "auto", "fontSize": "0.9rem" }} >
                                                        <Table responsive className="table--bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{"vertical-align": "initial"}} key={12}> <h5>CAMPO  </h5>  </th>
                                                                    <th style={{"vertical-align": "initial"}} key={13}> <h5>POZO </h5> </th>
                                                                    <th style={{"vertical-align": "initial"}} key={14}> <h5>TOTALIZADOR (m³) </h5> </th>
                                                                    <th style={{"vertical-align": "initial"}} key={15}> <h5>CAUDAL INSCRITO (l/s) </h5> </th>
                                                                    <th style={{"vertical-align": "initial"}} key={16}> <h5>PROMEDIO CAUDAL (l/s) </h5> </th>
                                                                    <th style={{"vertical-align": "initial"}} key={17}> <h5>NIVEL FREATICO (mts) </h5> </th>                                                                    
                                                                    <th style={{"vertical-align": "initial"}} key={18}> <h5>STANDARD DE POZO  </h5></th>
                                                                    <th style={{"vertical-align": "initial"}} key={19}> <h5>TIEMPO ÚLTIMO ENVIO </h5></th>
                                                                    <th style={{"vertical-align": "initial"}} key={20}> <h5>ENVIO  </h5></th>
                                                                    <th style={{"vertical-align": "initial"}} key={21}> <h5>TIPO ENVIO  </h5></th>
                                                                    <th style={{"vertical-align": "initial"}} key={22}> <h5>USUARIO RESPONSABLE </h5></th>
                                                                   </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.tablaHomeEnviados.map(item => (
                                                                    <tr key={item.codigoDeLaObra}>
                                                                        <td><h5> {item.nombre_campo}</h5></td>
                                                                        <td><h5>{item.nombre_pozo}</h5></td>
                                                                        <td><h5>{item.totalizador}</h5></td>                                                                
                                                                        <td><h5>{item.lts_segundo}</h5></td>                                      
                                                                        <td><h5>{item.caudal}</h5></td>
                                                                        <td><h5>{item.nivelFreaticodelpozo}</h5></td>
                                                                        <td><h5>{item.standard}</h5></td>
                                                                        <td><h5>{item.horas}</h5></td>
                                                                        <td><h5>{this.cellEnviados(item.enviado)}</h5></td>
                                                                        <td><h5>{item.tipo_envio}</h5></td>
                                                                        <td><h5>{item.usuario_responsable}</h5></td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                            </div>
                                            </CardBody>
                                            </Fade>
                            )
                       }                       
                        </Card>
                    </Col>
                </React.Fragment >
                )
           }
        }
    }
}


const selector = formValueSelector('log_in_form')

export default compose(
    connect(state => {
        const { campo, mail } = selector(state, 'campo', 'mail')
        return {
            campo,
            mail
        }
    }), reduxForm({

        form: 'log_in_form',
    }))(Bandeja_entrada);
