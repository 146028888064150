import { Collapse,UncontrolledTooltip } from 'reactstrap';
import MailIcon from 'mdi-react/MailIcon';
import React, { PureComponent } from 'react';
import axios from 'axios';

export default class TopbarNoRegistros extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      tablaFaltantes: []
    };
    this.obtenerTablaFaltantes = this.obtenerTablaFaltantes.bind(this);
    this.handleCambio = this.handleCambio.bind(this);
  }

  componentDidMount() {
    this.obtenerTablaFaltantes();
  }

  obtenerTablaFaltantes() {
    const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
    try {
      this.setState({ isLoading: true })
      axios.post('https://dga.sanjoseapps.cl/DGA/api/tablaFaltantes.php', obj).then(response => {
        const datos = response.data
        if (datos !== undefined) {
          this.setState({ tablaFaltantes: datos })
        }
      });

    } catch (error) {
      console.error(error);
    }
  }
  handleCambio() {
    if (this.state.collapse === false) {
      this.setState({ collapse: true })
    } else {
      this.setState({ collapse: false })
    }
  }

  render() {
    return (

      <div className="topbar__collapse">
        <button className="topbar__btn topbar__btn--mail topbar__btn--new" id="boton" type="button" onClick={this.handleCambio}>
          <MailIcon />
          <div className="topbar__btn-new-label">
            <div />
          </div>
        </button>
        <UncontrolledTooltip placement="bottom" target="boton">
             Pozos sin datos recibidos
            </UncontrolledTooltip>

        {this.state.collapse && <button className="topbar__back" type="button" onClick={this.handleCambio} />}
        <Collapse
          isOpen={this.state.collapse}
          className="topbar__collapse-content"
        >
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Pozos sin datos</p>
          </div>
          {this.state.tablaFaltantes.map(notification => (notification.alerta !== "3" ? "" :
            <div className="topbar__collapse-item" style={{ "padding": "1px 55px 10px 35px" }} key={notification.id}>
              <p className="topbar__collapse-message">
                <span className="topbar__collapse-name">{notification.nombre_pozo + ", " + notification.nombre_campo}</span>
                {notification.alerta === "3" ? (" No posee registros ingresados") : (notification.alerta === "2" ? (" registros no han sido actualizados ") : " nuevos registros ingresados.")}
              </p>
              <p className="topbar__collapse-date" style={{ "right": "35px" }}>{notification.horas_envio}</p>
            </div>
          ))}
       
        </Collapse>
      </div>
    );
  }
}