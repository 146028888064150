import { UncontrolledTooltip, Modal, ModalBody, ModalHeader } from 'reactstrap';
import MailIcon from 'mdi-react/EmailBoxIcon';
import React, { useState } from 'react';
import EnvioMail from '../../EnvioMail/components/EnvioMail';


const TopbarMail = (props) => {
  const [modal, setModal] = useState(false);
  const [backdrop] = useState(true);
  const [keyboard] = useState(true);

  const toggle = () => setModal(!modal);

    return (
      <div className="topbar__collapse">
        <button className="topbar__btn topbar__btn--mail topbar__btn--new" id="envioMail" type="button" onClick={toggle}>
          <MailIcon />
        </button>
        <UncontrolledTooltip placement="bottom" target="envioMail">
          Envio Mail a Soporte
        </UncontrolledTooltip>
        <Modal isOpen={modal} toggle={toggle} backdrop={backdrop} keyboard={keyboard} >
          <ModalHeader>REPORTAR PROBLEMA</ModalHeader>
          <ModalBody>
            <EnvioMail />
          </ModalBody>
        </Modal>
      </div>
    );
  }



export default TopbarMail;