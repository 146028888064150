import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Form} from 'react-bootstrap';

import { Card, CardBody, Col } from 'reactstrap';

class CambioContraseña extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            contraseña: "",
            apellido: "",
            correo: "",
            pass: "",
            alertEnvioExitoso: false,
            alertContraseñaDif: false,
            nombre: "",
            segundaPass: "",
            dv: ""
        };
        this.actualizarUsuario = this.actualizarUsuario.bind(this);
        this.handleContraseñaActual = this.handleContraseñaActual.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.handleChangeSegundaPass = this.handleChangeSegundaPass.bind(this);
    }

    handleContraseñaActual(event) {
        event.preventDefault();
        this.setState({ contraseña: event.target.value });
    }
    handleChangePass(event) {
        event.preventDefault();
        this.setState({ pass: event.target.value });
    }
    handleChangeSegundaPass(event) {
        event.preventDefault();
        this.setState({ segundaPass: event.target.value });
    }

    
    actualizarUsuario(event) {
        event.preventDefault();
        if(this.state.segundaPass === this.state.pass ){
            
        const obj = { pass: this.state.pass, idtbl_usuario: localStorage.getItem("idtbl_usuario")};
        try {
            if (this.state.segundaPass === this.state.pass) {
                axios.post('https://dga.sanjoseapps.cl/DGA/api/cambioContrasena.php', obj);
                this.setState({ alertEnvioExitoso: true });
                setTimeout(() => { this.setState({ alertEnvioExitoso: false }) }, 5000)
            } else {
                alert("Las contraseñas deben coincidir");
            }
        } catch (error) {
            //console.log(error);
        }
    }else{
        this.setState({ alertContraseñaDif: true });
        this.setState({ pass: "" });
        this.setState({ segundaPass: "" });
       
        setTimeout(() => { this.setState({ alertContraseñaDif: false }) }, 5000)
    }
    }

    render() {
        return (
            <React.Fragment>
               <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                    <Alert.Heading>¡Cambio de contraseña exitoso!</Alert.Heading>
                    <p>
                        Se ha actualizado la contraseña.
                </p>
                </Alert>
                <Alert variant="warning" show={this.state.alertContraseñaDif} onClose={() => this.setState({ alertContraseñaDif: false })} dismissible>
                    <Alert.Heading> Error </Alert.Heading>
                    <p>
                       La nueva contraseña no coincide con su validación. 
                </p>
                </Alert>
                <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text">En esta pestaña se podrá cambiar su contraseña</h5>
                                </div>
            

                                <form onSubmit={this.actualizarUsuario} className="login__form active" id="register__form">
                             

                                    <div className="row">
                                        <div className="col-md-6" style={{ "margin-left": "0" }}>
                                            <div>  <h4>  CONTRASEÑA ACTUAL </h4></div>
                                            <div>
                                            <Form.Control type="password" placeholder="Contraseña actual" value={this.state.contraseña} onChange={this.handleContraseñaActual} maxLength={45} required   />
                                          </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{ "margin-left": "0" }}>
                                            <div>  <h4>  NUEVA CONTRASEÑA </h4> </div>
                                            <div>
                                            <Form.Control  type="password" placeholder="Contraseña Nueva" value={this.state.pass} onChange={this.handleChangePass} maxLength={45} required   />
                                             </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{ "margin-left": "0" }}>
                                            <div>   <h4>  VALIDACIÓN NUEVA CONTRASEÑA  </h4> </div>
                                            <div>
                                            <Form.Control  type="password" placeholder="Validación Nueva Contraseña " value={this.state.segundaPass} onChange={this.handleChangeSegundaPass} maxLength={45} required    />
                                             </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6" style={{ "margin-left": "0" }}>
                                            <div>
                                            <Form.Control type="submit" value="Registrar usuario"     />
                                              </div>
                                        </div>
                                    </div>
                                </form>
                    </CardBody>
                 </Card>
            </Col>
            </React.Fragment>
        )
    }
}

export default CambioContraseña;